import React, {Component} from "react";
import {Modal, Input} from 'antd';


class AddPhotoTypeModal extends Component {


   componentDidUpdate(prevProps, prevState, snapshot) {
       if(!prevProps.visible && this.props.visible) {
           this.setState({'Type': "", 'EMail': ""})
       }
   }


    render() {

        /*
        footer={[
                    <Button key="back" icon={"download"} type="default" onClick={() => window.open(this.props.file.url, "_blank")}>
                        Download
                    </Button>
                    ,
                    <Button key="submit" icon={"close"} type="primary" onClick={this.props.onCancel}>
                        Close
                    </Button>,
                ]}
         */

        return (
            <Modal
                visible={this.props.visible}
                title="Add Photo-Type"
                onOk={() => this.props.onOk(this.state)}
                onCancel={this.props.onCancel}
                cancelText={"Close"}
                okText={"Add"}
                width={"750px"}

            >

                {/* onChange={(e) => this.setState({'Type': e})} */}
                <Input
                    type={"text"}
                    placeholder="Type"
                    onChange={(e) => this.setState({'Type': e.target.value})}
                    value={this.state?.Type || ""}
                />

                <br />
                <br />

                <Input
                    type={"email"}
                    placeholder="EMail"
                    onChange={(e) => this.setState({'EMail': e.target.value})}
                    value={this.state?.EMail || ""}
                />



            </Modal>
        );
    }
}



export default AddPhotoTypeModal;
