import actions from './actions';

const initState =
    {
        idToken: localStorage.getItem('id_token') || false,
        loadingLogin: false,
        passwordChangeSaving: false,
        passwordChangeSuccess: false
    };

export default function AuthReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_REQUEST:
            return Object.assign({}, state, {
                loadingLogin: true
            });
        case actions.LOGIN_SUCCESS:
            localStorage.setItem('id_token', action.response.data.token);
            return Object.assign({}, state, {
                idToken: action.response.data.token,
                loadingLogin: false
            });
        case actions.LOGIN_ERROR:
            return Object.assign({}, state, {
                loadingLogin: false
            });
        case actions.LOGOUT:
            return Object.assign({}, state, {
                idToken: false
            });
        case actions.PW_CHANGE_REQUEST:
            return Object.assign({}, state, {
                passwordChangeSaving: true,
                passwordChangeSuccess: false
            });
        case actions.PW_CHANGE_SUCCESS:
            return Object.assign({}, state, {
                passwordChangeSaving: false,
                passwordChangeSuccess: true
            });
        case actions.PW_CHANGE_ERROR:
            return Object.assign({}, state, {
                passwordChangeSaving: false,
                passwordChangeSuccess: false
            });
        default:
            return state;
    }
}
