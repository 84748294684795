import React, {Component} from "react";
import { Form, Select, Modal, Input, Checkbox, Icon, Button } from 'antd';
import {bindActionCreators} from "redux";
import * as EmergencyActions from "../redux/Emergency/actions";
import {connect} from "react-redux";


const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;


const formItemLayout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 13 },
    },
};



class EmergencyCaseModal extends Component {


    componentWillMount() {
        this.props.actions.loadEmergencys();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                //values = {...values, CaseID: this.props.Emergency.CaseID};
                if( this.props.Emergency.CaseID ) {
                    this.props.actions.saveEmergencyCase(values, this.props.Emergency.CaseID);
                } else {
                    this.props.actions.createEmergencyCase(values);
                }
            }
        });
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.EmergencyCaseModalVisible && !nextProps.EmergencyCaseModalVisible) {
            this.props.form.resetFields();
        }
    }

    confirmDelete = () => {
        confirm({
            title: "Delete emergency case",
            content: "Do you really want to delete this emergency? It will be removed from all hospitals!",
            okText: "Delete",
            okType: 'danger',
            cancelText: "cancel",
            onOk: () => this.props.actions.deleteEmergencyCase(this.props.Emergency.CaseID),
            onCancel() {
            },
        });
    };



    render() {
        const { getFieldDecorator } = this.props.form;


        return (
                <Modal
                    title={`${this.props.Emergency.CaseID ? 'Edit' : 'Add'} emergency case`}
                    visible={this.props.EmergencyCaseModalVisible}
                    onOk={this.handleSubmit}
                    onCancel={() => this.props.actions.showEmergencyCaseModal(false)}
                    cancelText={"Cancel"}
                    okText={"Save"}
                    width={"750px"}
                    footer={[
                        this.props.Emergency.CaseID ? <Button key="deleteEmergencyCase" icon={"delete"} type="danger" loading={this.props.deletingEmergencyCase} onClick={this.confirmDelete}>
                            Delete case
                        </Button> : '',
                        <Button key="cancel" icon={"close"} onClick={() => this.props.actions.showEmergencyCaseModal(false)}>Cancel</Button>,
                        <Button key="submit" icon={"check"} type="primary" loading={this.props.savingEmergencyCase} onClick={this.handleSubmit}>
                            Save
                        </Button>
                    ]}
                >



                    <Form onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        <FormItem
                            {...formItemLayout}
                            label={"Emergency Case"}
                            required={true}
                            key={'EmergencyCase'}
                        >
                            {getFieldDecorator('EmergencyCase', {
                                initialValue: this.props.Emergency.CaseName,
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: "Please enter a emergency-case"
                                }],
                            })(
                                <Input type={"text"}/>
                            )}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={"Emergency"}
                            required={true}
                            key={'EmergencyID'}
                        >
                            {getFieldDecorator('EmergencyID', {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: this.props.Emergency.EmergencyID,
                                rules: [{
                                    required: true,
                                    message: "Please select an emergency",
                                }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Please select an emergency"
                                    optionFilterProp="children"
                                    mode="multiple"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.props.Emergencys.map(e => <Option key={e.EmergencyID} value={e.EmergencyID}>{e.EmergencyName}</Option>)}
                                </Select>
                            )}
                        </FormItem>


                    </Form>


                </Modal>


        );
    }
}



function mapStateToProps(state) {
    const { EmergencyReducer } = state;
    const { Emergencys, Emergency, EmergencyCaseModalVisible, savingEmergencyCase, deletingEmergencyCase } = EmergencyReducer;
    return {
        Emergencys,
        Emergency,
        EmergencyCaseModalVisible,
        savingEmergencyCase,
        deletingEmergencyCase
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(EmergencyActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EmergencyCaseModal));
