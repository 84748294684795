import notification from '../components/Notification';
//import { history } from '../history/history';

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;

export function callApi(endpoint, type, data = {}, raw = false) {

    let token = localStorage.getItem('id_token') || null;
    let config = {};

    let url = BASE_URL+"api/" + API_VERSION + "/";


    if(type === 'GET' || type === 'DELETE') {
        config = {
            method: type,
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': 0 },
            cache: "no-cache"
        };
    } else if(type === 'POST' || type === 'PUT') {
        config = {
            method: type,
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': 0 },
            cache: "no-cache",
            body: JSON.stringify(data)
        };
    }

    if(raw) {
        return fetch(url + endpoint, config)
            .then(function(response) {
                if (!response.ok) {
                    return Promise.reject(response);
                }
                return response;
            }).catch(err => {
                return Promise.reject(err);
            });
    }

    return fetch(url + endpoint, config)
        .then(function(response) {
            if (!response.ok) {
                return Promise.reject(response);
            } else {
                return response.json();
            }

        }).then(function(response) {
            if(response.alert && response.alert.message) {
                if(response.alert.duration !== null && response.alert.duration !== undefined) {
                    notification(response.alert.type, response.alert.message, response.alert.description, response.alert.duration);
                } else {
                    notification(response.alert.type, response.alert.message);
                }
            }
            /*if(typeof(response.redirect) !== 'undefined' && response.redirect) {
                history.push(response.redirect);
            }*/
            return response;
        }).catch(err => {
            return Promise.reject(err);
        });
}


export const CALL_API = Symbol('Call API');

export default store => next => action => {

    const callAPI = action[CALL_API];




    // So the middleware doesn't get applied to every single action
    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    let { endpoint, types, reqType, postData, raw, reducerPayload } = callAPI;

    const [ requestType, successType, errorType ] = types;


    next({
        type: requestType,
        reducerPayload
    });

    return callApi(endpoint ,reqType, postData, raw).then(

        response =>
            next({
                response,
                type: successType,
                reducerPayload
            }),
        function(error) {

            if (error.status === 500 || error.status === 400 || error.status === 401) {

                error.json().then(result => {


                    if(error.status === 401 && localStorage.getItem('id_token')) {
                        localStorage.removeItem('id_token');
                        window.location.href = "/login";
                    } else {
                        if (result.alert && result.alert.message) {
                            notification(result.alert.type, result.alert.message);
                        }
                        next({
                            type: errorType,
                            reducerPayload
                        });
                    }
                });


            } else {
                notification('error', "an unknown error occoured");
                next({
                    type: errorType,
                    reducerPayload
                });
            }
        }
    );
};



