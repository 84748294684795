import React, {Component} from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './Login';
import Recover from './Recover';
import Logout from './Logout';
import App from './App';


class PublicRoutes extends Component {

    //<Route path='*' component={NotFound}/>

    render() {
        return (
            <Switch>
                <Route exact path='/login' component={Login}/>
                <Route exact path='/logout' component={Logout}/>
                <Route exact path='/' component={Login}/>
                <Route exact path='/recover/:RecoverToken' component={Recover}/>
                <Route path='/app' component={App}/>
            </Switch>
        );
    }
}

export default PublicRoutes;
