import actions from './actions';

const initState =
    {
        allEmergencys: [],
        Emergencys: [],
        Emergency: {},
        savingEmergencyCase: false,
        deletingEmergencyCase: false,
        EmergencyCaseModalVisible: false,
        EmergencyCases: [],
        loadingEmergencyCasesList: false,
        paginationEmergencyCases: {},
        infosTableEmergencyCases: {sortField: 'CaseID', sortOrder: 'descend'},
        EmergencyCaseSearch: "",
    };

export default function EmergencyReducer(state = initState, action) {
    switch (action.type) {
        case actions.SHOW_EMERGENCYCASE_MODAL:
            return Object.assign({}, state, {
                EmergencyCaseModalVisible: action.show,
                Emergency: action.emergency
            });
        case actions.LOAD_EMERGENCYS_SUCCESS:
            return Object.assign({}, state, {
                Emergencys: action.response.data.Emergencys
            });
        case actions.LOAD_ALL_EMERGENCY_SUCCESS:
            return Object.assign({}, state, {
                allEmergencys: action.response.data.Emergencys
            });

        case actions.SAVE_EMERGENCYCASE_REQUEST:
            return Object.assign({}, state, {
                savingEmergencyCase: true,
            });
        case actions.SAVE_EMERGENCYCASE_SUCCESS:
            return Object.assign({}, state, {
                EmergencyCaseModalVisible: false,
                savingEmergencyCase: false,
            });
        case actions.SAVE_EMERGENCYCASE_ERROR:
            return Object.assign({}, state, {
                savingEmergencyCase: false,
            });


        case actions.LOAD_TABLE_REQUEST:
            return Object.assign({}, state, {
                loadingEmergencyCasesList: true
            });
        case actions.LOAD_TABLE_SUCCESS:
            const pagination = { ...state.paginationEmergencyCases };
            // Read total count from server
            // pagination.total = data.totalCount;
            pagination.total = action.response.data.Count;

            return Object.assign({}, state, {
                EmergencyCases: action.response.data.EmergencyCases,
                editAllowed: action.response.data.editAllowed,
                loadingEmergencyCasesList: false,
                paginationEmergencyCases: pagination
            });
        case actions.LOAD_TABLE_ERROR:
            return Object.assign({}, state, {
                loadingEmergencyCasesList: false
            });
        case actions.SET_EMERGENCYCASES_TABLE_PAGE:
            return Object.assign({}, state, {
                paginationEmergencyCases: action.pager
            });
        case actions.SET_EMERGENCYCASES_TABLE_INFO:
            return Object.assign({}, state, {
                infosTableEmergencyCases: action.infos
            });
        case actions.SET_EMERGENCYCASES_SEARCH_TEXT:
            return Object.assign({}, state, {
                EmergencyCaseSearch: action.search,
                paginationEmergencyCases: {current: 1},
                infosTableEmergencyCases: {}
            });
        default:
            return state;
    }
}
