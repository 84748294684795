import React from 'react';
import '../styles/css/ActionFooter.css';

const ActionFooter = (props)  => {
        return (
            <div
                className={"actionFooter"}
            >
                {props.children}
            </div>
        );
};

export default ActionFooter;