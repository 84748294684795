import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {Button, Table, Row, Col, Input, Icon, Tabs, Avatar} from "antd";
import connect from "react-redux/es/connect/connect";
import * as EmployeeActions from "../../redux/Employees/actions";
import history from "../../history/index";
import ActionFooter from '../../components/actionFooter';
import ContentContainer from "../../components/ContentContainer";
//import ContentWrapper from "../../components/ContentWrapper";
const { TabPane } = Tabs;


const Search = Input.Search;


class Employees extends Component{

    showEmployee(id) {
        history.push("/app/employees/" + id);
    }

    componentWillMount() {
        this.props.actions.LoadEmployeeTable({...this.props.infosTableEmployees, search: this.props.EmployeeSearch});
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationEmployees };
        pager.current = pagination.current;

        this.props.actions.setEmployeesTablePage(pager);

        this.props.actions.setEmployeesTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.LoadEmployeeTable({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.EmployeeSearch
        });
    };

    onChangeSearch = (e) => {
        this.props.actions.setEmployeesSearchText(e.target.value);
    };



    render(){


        /*
        {
                title: '',
                dataIndex: 'id',
                key: 'icon',
                render: text => <Icon type={"team"}/>,
                width: 50
            }

            {
                title: '',
                dataIndex: 'FirstName',
                key: 'icon',
                render: text =>  <Avatar style={{ backgroundColor: '#b8e7cf', verticalAlign: 'middle', color: '#309969'}} size="large">{text.charAt(0).toUpperCase()}H</Avatar>,
                width: 50
            }
         */

        const columns = [
            {
                title: '',
                dataIndex: 'id',
                key: 'icon',
                render: text => <Icon type={"team"}/>,
                width: 50
            }, {
                title: 'EmployeeID',
                dataIndex: 'EmployeeID',
                key: 'id',
                sorter: true,
                sortOrder: this.props.infosTableEmployees.sortField === 'EmployeeID' && this.props.infosTableEmployees.sortOrder,
            },
            {
                title: 'Title',
                dataIndex: 'Title',
                key: 'Title',
                sorter: true,
                sortOrder: this.props.infosTableEmployees.sortField === 'Title' && this.props.infosTableEmployees.sortOrder
            },
            {
                title: 'First Name',
                dataIndex: 'FirstName',
                key: 'FirstName',
                sorter: true,
                sortOrder: this.props.infosTableEmployees.sortField === 'FirstName' && this.props.infosTableEmployees.sortOrder,
            },
            {
                title: 'Last Name',
                dataIndex: 'LastName',
                key: 'LastName',
                sorter: true,
                sortOrder: this.props.infosTableEmployees.sortField === 'LastName' && this.props.infosTableEmployees.sortOrder,
            }
        ];



        return (
            <ContentContainer head={<Row style={{width: '100%'}}>
                <Col span={12} className={"HeaderText"}>
                    <Icon type={"team"} />
                    <span>Staff</span>
                </Col>
                <Col span={12}>
                    <Search onChange={this.onChangeSearch} value={this.props.EmployeeSearch} suffix={<Icon type="close-circle" onClick={() => {this.props.actions.setEmployeesSearchText(''); this.props.actions.LoadEmployeeTable( {...this.props.infosTableEmployees, search: ''});}} />} placeholder={"Search..."} onSearch={() => this.props.actions.LoadEmployeeTable( {...this.props.infosTableEmployees, search: this.props.EmployeeSearch})}  enterButton style={{width: '400px', minHeight: '0px !important', float: 'right'}} />
                </Col>
            </Row>}>


                    <Table
                        columns={columns}
                        loading={this.props.loadingEmployeesList}
                        dataSource={this.props.Employees}
                        onRow={(record) => ({
                            onClick: () => {
                                this.showEmployee(record.EmployeeID);
                            }
                        })}
                        rowClassName={(record, index) => 'table-clickable'}
                        pagination={this.props.paginationEmployees}
                        onChange={this.handleTableChange}
                    />


                <ActionFooter>
                    <div className="isoLeft">
                        <Button
                            type="default"
                            htmlType="button"
                            onClick={() => history.goBack()}
                        >
                            <Icon type="left"/>Back
                        </Button>
                    </div>
                    <div className="isoRight">
                        <Button onClick={() => this.showEmployee(0)} type="primary">
                            <Icon type="plus"/>Add
                        </Button>
                    </div>
                </ActionFooter>

            </ContentContainer>
        );
    }
}

function mapStateToProps(state) {
    const {EmployeeReducer} = state;
    const {Employees, loadingEmployeesList, paginationEmployees, infosTableEmployees, EmployeeSearch} = EmployeeReducer;
    return {
        Employees,
        loadingEmployeesList,
        paginationEmployees,
        infosTableEmployees,
        EmployeeSearch,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(EmployeeActions, dispatch)
    };
}




export default connect(mapStateToProps, mapDispatchToProps)(Employees);