import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {Button, Table, Row, Col, Input, Icon, Tabs, Avatar} from "antd";
import connect from "react-redux/es/connect/connect";
import * as UserActions from "../../redux/Users/actions";
import history from "../../history/index";
import ActionFooter from '../../components/actionFooter';
import ContentContainer from "../../components/ContentContainer";
//import ContentWrapper from "../../components/ContentWrapper";
const { TabPane } = Tabs;


const Search = Input.Search;


class Users extends Component{

    showUser(id) {
        history.push("/app/users/" + id);
    }

    componentWillMount() {
        //this.props.actions.setActiveStatus("");
        this.props.actions.LoadUserTable({...this.props.infosTableUsers, search: this.props.UserSearch, activeStatus: this.props.UserActiveStatus});
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationUsers };
        pager.current = pagination.current;

        this.props.actions.setUsersTablePage(pager);

        this.props.actions.setUsersTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.LoadUserTable({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.UserSearch,
            activeStatus: this.props.UserActiveStatus
        });
    };

    onChangeSearch = (e) => {
        this.props.actions.setUsersSearchText(e.target.value);
    };



    render(){


        /*
        {
                title: '',
                dataIndex: 'id',
                key: 'icon',
                render: text => <Icon type={"user"}/>,
                width: 50
            }

            {
                title: '',
                dataIndex: 'FirstName',
                key: 'icon',
                render: text =>  <Avatar style={{ backgroundColor: '#b8e7cf', verticalAlign: 'middle', color: '#309969'}} size="large">{text.charAt(0).toUpperCase()}H</Avatar>,
                width: 50
            }
         */

        const columns = [
            {
                title: '',
                dataIndex: 'id',
                key: 'icon',
                render: text => <Icon type={"user"}/>,
                width: 50
            }, {
                title: 'UserID',
                dataIndex: 'UserID',
                key: 'id',
                sorter: true,
                sortOrder: this.props.infosTableUsers.sortField === 'UserID' && this.props.infosTableUsers.sortOrder,
            }, {
                title: 'First Name',
                dataIndex: 'FirstName',
                key: 'FirstName',
                sorter: true,
                sortOrder: this.props.infosTableUsers.sortField === 'FirstName' && this.props.infosTableUsers.sortOrder,
            },
            {
                title: 'Last Name',
                dataIndex: 'LastName',
                key: 'LastName',
                sorter: true,
                sortOrder: this.props.infosTableUsers.sortField === 'LastName' && this.props.infosTableUsers.sortOrder,
            },
            {
                title: 'E-Mail',
                dataIndex: 'EMail',
                key: 'EMail',
                sorter: true,
                sortOrder: this.props.infosTableUsers.sortField === 'EMail' && this.props.infosTableUsers.sortOrder,
            },
            {
                title: 'Active',
                dataIndex: 'Active',
                key: 'Active',
                sorter: true,
                sortOrder: this.props.infosTableUsers.sortField === 'Active' && this.props.infosTableUsers.sortOrder,
                render: text => text === 0 ? 'not confirmed' : text === 1 ? 'active' : text === -1 ? 'blocked' : 'N/A'
            }
        ];



        return (
            <ContentContainer head={<Row style={{width: '100%'}}>
                <Col span={12} className={"HeaderText"}>
                    <Icon type={"user"} />
                    <span>Users</span>
                </Col>
                <Col span={12}>
                    <Search onChange={this.onChangeSearch} value={this.props.UserSearch} suffix={<Icon type="close-circle" onClick={() => {this.props.actions.setUsersSearchText(''); this.props.actions.LoadUserTable( {...this.props.infosTableUsers, search: ''});}} />} placeholder={"Search..."} onSearch={() => this.props.actions.LoadUserTable( {...this.props.infosTableUsers, search: this.props.UserSearch, activeStatus: this.props.UserActiveStatus})}  enterButton style={{width: '400px', minHeight: '0px !important', float: 'right'}} />
                </Col>
            </Row>}>
                <Tabs defaultActiveKey={this.props.UserActiveStatus} onChange={(activeStatus) => {this.props.actions.setActiveStatus(activeStatus); this.props.actions.LoadUserTable( {...this.props.infosTableUsers, activeStatus});}}>
                    {/*<TabPane tab="All Users" key="">

                    </TabPane>
                    <TabPane tab="Not Confirmed" key="0">

                    </TabPane>
                    <TabPane tab="Active" key="1">

                    </TabPane>
                    <TabPane tab="Blocked" key="2">

                    </TabPane>*/}


                    <TabPane  tab={
                        <span>
                          <Icon type="info-circle" />
                          All Users
                        </span>} key="">

                    </TabPane>
                    <TabPane tab={
                        <span>
                          <Icon type="question-circle" />
                          Not Confirmed
                        </span>} key="0">

                    </TabPane>
                    <TabPane tab={
                        <span>
                          <Icon type="check-circle" />
                          Active
                        </span>}  key="1">

                    </TabPane>
                    <TabPane tab={
                        <span>
                          <Icon type="close-circle" />
                          Blocked
                        </span>} key="2">

                    </TabPane>




                </Tabs>


                    <Table
                        columns={columns}
                        loading={this.props.loadingUsersList}
                        dataSource={this.props.Users}
                        onRow={(record) => ({
                            onClick: () => {
                                this.showUser(record.UserID);
                            }
                        })}
                        rowClassName={(record, index) => 'table-clickable'}
                        pagination={this.props.paginationUsers}
                        onChange={this.handleTableChange}
                    />


                <ActionFooter>
                    <div className="isoLeft">
                        <Button
                            type="default"
                            htmlType="button"
                            onClick={() => history.goBack()}
                        >
                            <Icon type="left"/>Back
                        </Button>
                    </div>
                    <div className="isoRight">
                        <Button onClick={() => this.showUser(0)} type="primary">
                            <Icon type="plus"/>Add
                        </Button>
                    </div>
                </ActionFooter>

            </ContentContainer>
        );
    }
}

function mapStateToProps(state) {
    const {UserReducer} = state;
    const {Users, loadingUsersList, paginationUsers, infosTableUsers, UserSearch, UserActiveStatus} = UserReducer;
    return {
        Users,
        loadingUsersList,
        paginationUsers,
        infosTableUsers,
        UserSearch,
        UserActiveStatus
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(UserActions, dispatch)
    };
}




export default connect(mapStateToProps, mapDispatchToProps)(Users);