import HospitalActions from "./actions";
import history from "../../history/index";

const initState = {
    Hospitals: [],
    Hospital: {},
    loadingHospitalsList: false,
    paginationHospitals: { defaultPageSize: 15 },
    infosTableHospitals: {sortField: 'HospitalID', sortOrder: 'descend', results:15},
    HospitalSearch: "",

    deletingHospital: false,


    loadingHospitalImage: false,
    HospitalImage: "",
    savingHospitalDetail: false,
    savingHospitalDetailStatus: 0,
    loadingHospitalDetail: false,

    previewFileModalVisible: false,
    previewFileModalFile: {},

    staffModalVisible: false,
    photoTypeModalVisible: false
};

export default function HospitalReducer(state = initState, action) {
  switch (action.type) {
    case HospitalActions.LOAD_TABLE_REQUEST:
        return Object.assign({}, state, {
            loadingHospitalsList: true
        });
    case HospitalActions.LOAD_TABLE_SUCCESS:
        const pagination = { ...state.paginationHospitals };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = action.response.data.Count;

        return Object.assign({}, state, {
            Hospitals: action.response.data.Hospitals,
            loadingHospitalsList: false,
            paginationHospitals: pagination
        });
      case HospitalActions.LOAD_TABLE_ERROR:
          return Object.assign({}, state, {
              loadingHospitalsList: false
          });
      case HospitalActions.SET_HOSPITAL_IMAGE_LOADING:
          return Object.assign({}, state, {
              HospitalImage: "",
              loadingHospitalImage: action.loading
          });
      case HospitalActions.SET_HOSPITAL_IMAGE:
          return Object.assign({}, state, {
              HospitalImage: action.image
          });
      case HospitalActions.LOAD_HOSPITAL_IMAGE_REQUEST:
          return Object.assign({}, state, {
              HospitalImage: "",
              loadingHospitalImage: true
          });
      case HospitalActions.LOAD_HOSPITAL_IMAGE_SUCCESS:
          return Object.assign({}, state, {
              HospitalImage: action.response.data.Image,
              loadingHospitalImage: false
          });
      case HospitalActions.LOAD_HOSPITAL_IMAGE_ERROR:
          return Object.assign({}, state, {
              loadingHospitalImage: false
          });
      case HospitalActions.SET_HOSPITAL_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationHospitals: action.pager
          });
      case HospitalActions.SET_HOSPITAL_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableHospitals: action.infos
          });
      case HospitalActions.SET_HOSPITAL_SEARCH_TEXT:
          return Object.assign({}, state, {
              HospitalSearch: action.search,
              paginationHospitals: {current: 1},
              infosTableHospitals: {}
          });
      case HospitalActions.SHOW_PREVIEW_FILE_MODAL:
          return Object.assign({}, state, {
              previewFileModalVisible: action.show,
              previewFileModalFile: action.file
          });
      case HospitalActions.LOAD_HOSPITAL_DETAIL_REQUEST:
          return Object.assign({}, state, {
              loadingHospitalDetail: true
          });
      case HospitalActions.LOAD_HOSPITAL_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              Hospital: action.response.data.Hospital,
              loadingHospitalDetail: false
          });
      case HospitalActions.LOAD_HOSPITAL_DETAIL_ERROR:
          return Object.assign({}, state, {
              loadingHospitalDetail: false
          });

      case HospitalActions.SAVE_HOSPITAL_DETAIL_REQUEST:
          return Object.assign({}, state, {
              savingHospitalDetail: true,
              savingHospitalDetailStatus: -2
          });
      case HospitalActions.SAVE_HOSPITAL_DETAIL_SUCCESS:
          let HospitalID = 0;
          if(action.response.data.HospitalID) {
              HospitalID = action.response.data.HospitalID;
          }
          return Object.assign({}, state, {
              savingHospitalDetail: false,
              savingHospitalDetailStatus: HospitalID
          });
      case HospitalActions.SAVE_HOSPITAL_DETAIL_ERROR:
          return Object.assign({}, state, {
              savingHospitalDetail: false,
              savingHospitalDetailStatus: -1
          });

      case HospitalActions.CLEAR_HOSPITAL_DETAIL:
          return Object.assign({}, state, {
              Hospital: {},
              HospitalImage: ""
          });

      case HospitalActions.DELETE_HOSPITAL_REQUEST:
          return Object.assign({}, state, {
              deletingHospital: true
          });
      case HospitalActions.DELETE_HOSPITAL_SUCCESS:
          return Object.assign({}, state, {
              deletingHospital: false
          });
      case HospitalActions.DELETE_HOSPITAL_ERROR:
          return Object.assign({}, state, {
              deletingHospital: false
          });

      case HospitalActions.SHOW_STAFF_MODAL:
          return Object.assign({}, state, {
              staffModalVisible: action.show
          });

      case HospitalActions.SHOW_PHOTOTYPE_MODAL:
          return Object.assign({}, state, {
              photoTypeModalVisible: action.show
          });

    default:
      return state;
  }
}

