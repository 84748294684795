import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {Button, Table, Row, Col, Input, Icon} from "antd";
import connect from "react-redux/es/connect/connect";
import * as NotificationActions from "../../redux/Notification/actions";
import history from "../../history/index";
import ActionFooter from '../../components/actionFooter';
import ContentContainer from "../../components/ContentContainer";
import NewNotificationModal from "../../components/NewNotificationModal";
import moment from 'moment';

const Search = Input.Search;


class Notifications extends Component{

    createNotification() {
        this.props.actions.showNewNotificationModal(true);
    }

    componentWillMount() {
        //this.props.actions.setActiveStatus("");
        this.props.actions.LoadNotificationTable({...this.props.infosTableNotifications, search: this.props.NotificationsSearch});
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationNotifications };
        pager.current = pagination.current;

        this.props.actions.setNotificationsTablePage(pager);

        this.props.actions.setNotificationsTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.LoadNotificationTable({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.NotificationsSearch,
        });
    };

    onChangeSearch = (e) => {
        this.props.actions.setNotificationsSearchText(e.target.value);
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.NewNotificationModalVisible && !this.props.NewNotificationModalVisible && prevProps.savingNewNotification && !this.props.savingNewNotification)
        {
            this.props.actions.LoadNotificationTable({...this.props.infosTableNotifications, search: this.props.NotificationsSearch});
        }

    }



    render(){



        const columns = [
            {
                title: '',
                dataIndex: 'id',
                key: 'icon',
                render: text => <Icon type={"notification"}/>,
                width: 50
            }, {
                title: 'Title',
                dataIndex: 'Title',
                key: 'Title',
                sorter: true,
                sortOrder: this.props.infosTableNotifications.sortField === 'Title' && this.props.infosTableNotifications.sortOrder,
            },
            {
                title: 'Text',
                dataIndex: 'Text',
                key: 'Text',
                sorter: true,
                sortOrder: this.props.infosTableNotifications.sortField === 'Text' && this.props.infosTableNotifications.sortOrder,
            },
            {
                title: 'Sent to',
                dataIndex: 'SentTo',
                key: 'SentTo',
                sorter: true,
                sortOrder: this.props.infosTableNotifications.sortField === 'SentTo' && this.props.infosTableNotifications.sortOrder,
            },
            {
                title: 'Created',
                dataIndex: 'Created',
                key: 'Created',
                sorter: true,
                sortOrder: this.props.infosTableNotifications.sortField === 'Created' && this.props.infosTableNotifications.sortOrder,
                render: (text) => moment(text).format('MM/DD/YYYY hh:mm A')
            }
        ];



        return (
            <React.Fragment>
                <NewNotificationModal />
                <ContentContainer head={<Row style={{width: '100%'}}>
                    <Col span={12} className={"HeaderText"}>
                        <Icon type={"notification"} />
                        <span>Notifications</span>
                    </Col>
                    <Col span={12}>
                        <Search
                            onChange={this.onChangeSearch}
                            value={this.props.NotificationsSearch}
                            suffix={<Icon type="close-circle"
                                          onClick={() => {
                                              this.props.actions.setNotificationsSearchText('');
                                              this.props.actions.LoadNotificationTable( {...this.props.infosTableNotifications, search: ''});
                                          }} />}
                            placeholder={"Search..."}
                            onSearch={
                                () => this.props.actions.LoadNotificationTable( {...this.props.infosTableNotifications, search: this.props.NotificationsSearch})}
                            enterButton
                            style={{width: '400px', minHeight: '0px !important', float: 'right'}}
                        />
                    </Col>
                </Row>}>

                    <Table
                        columns={columns}
                        loading={this.props.loadingNotificationsList}
                        dataSource={this.props.Notifications}
                        pagination={this.props.paginationNotifications}
                        onChange={this.handleTableChange}
                    />


                    <ActionFooter>
                        <div className="isoLeft">
                            <Button
                                type="default"
                                htmlType="button"
                                onClick={() => history.goBack()}
                            >
                                <Icon type="left"/>Back
                            </Button>
                        </div>
                        <div className="isoRight">
                            <Button onClick={() => this.createNotification()} type="primary">
                                <Icon type="plus"/>Add
                            </Button>
                        </div>
                    </ActionFooter>

                </ContentContainer>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {NotificationReducer} = state;
    const {Notifications, loadingNotificationsList, paginationNotifications, infosTableNotifications, NotificationsSearch, NewNotificationModalVisible, savingNewNotification} = NotificationReducer;
    return {
        Notifications,
        loadingNotificationsList,
        paginationNotifications,
        infosTableNotifications,
        NotificationsSearch,
        NewNotificationModalVisible,
        savingNewNotification
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(NotificationActions, dispatch)
    };
}




export default connect(mapStateToProps, mapDispatchToProps)(Notifications);














/*import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {Button, Row, Col, Input, Icon, Form, Modal} from "antd";
import connect from "react-redux/es/connect/connect";
import * as NotificationActions from "../../redux/Notification/actions";
import history from "../../history/index";
import ActionFooter from '../../components/actionFooter';
import ContentContainer from "../../components/ContentContainer";

const {TextArea} = Input;
const FormItem = Form.Item;
const confirm = Modal.confirm;


class Push extends Component{


    componentWillMount() {
        //this.props.actions.setActiveStatus("");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.NotificationSuccess && this.props.NotificationSuccess) {
            //Notification successfully sent - we reset our form state
            this.props.form.resetFields();
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                confirm({
                    title: "Send Notification",
                    content: "Do you really want to send this notification?",
                    okText: "Send",
                    okType: 'primary',
                    cancelText: "cancel",
                    onOk: () => {
                        this.props.actions.SendNotification(values);
                    },
                    onCancel() {
                    },
                });
            }
        });





    };



    render(){

        const { getFieldDecorator, getFieldsError } = this.props.form;


        return (
            <ContentContainer head={<Row style={{width: '100%'}}>
                <Col span={12} className={"HeaderText"}>
                    <Icon type={"notification"} />
                    <span>Notification</span>
                </Col>
                <Col span={12}>
                </Col>
            </Row>}>

                <Form id={"form"} layout="vertical" onSubmit={this.handleSubmit}>

                    <Row>
                        <Col span={24}>
                            <FormItem
                                label={"Title"}
                                hasFeedback={true}
                            >
                                {getFieldDecorator('Title', {
                                    rules: [{required: true, message: 'please fill this information'}],
                                })(
                                    <Input type={"text"} disabled={this.props.NotificationSending}/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormItem
                                label={"Notification Text"}
                            >
                                {getFieldDecorator('Text', {
                                    rules: [{required: true, message: 'please fill this information'}],
                                })(
                                    <TextArea rows={5} disabled={this.props.NotificationSending} />
                                )}
                            </FormItem>
                        </Col>
                    </Row>

                </Form>


                <ActionFooter>
                    <div className="isoLeft">
                        <Button
                            type="default"
                            htmlType="button"
                            onClick={() => history.goBack()}
                        >
                            <Icon type="left"/>Back
                        </Button>
                    </div>
                    <div className="isoRight">
                        <Button
                            onClick={(e) => this.handleSubmit(e)}
                            type="primary"
                            disabled={this.props.NotificationSending}
                            loading={this.props.NotificationSending}
                        >
                            <Icon type="check"/>Send
                        </Button>
                    </div>
                </ActionFooter>

            </ContentContainer>
        );
    }
}

function mapStateToProps(state) {
    const {NotificationReducer} = state;
    const {NotificationSending, NotificationSuccess} = NotificationReducer;
    return {
        NotificationSending,
        NotificationSuccess
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(NotificationActions, dispatch)
    };
}


const NotificationForm = Form.create()(Push);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationForm);
*/
