import React, {Component} from 'react';
//import {Layout,Breadcrumb} from 'antd';
//const { Content } = Layout;

class ContentContainer extends Component {

    render() {
        return (
            <div className={"ContentContainer"}>
                <div className={"Head"}>
                    {this.props.head}
                </div>

                {/*<Breadcrumb className={"Head"}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>List</Breadcrumb.Item>
                    <Breadcrumb.Item>App</Breadcrumb.Item>
                </Breadcrumb>*/}
                <div className={`ContentContainer-Container ${this.props.borderRight ? 'borderRight' : ''}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ContentContainer;
