import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {Button, Table, Row, Col, Input, Icon, Tabs, Avatar} from "antd";
import connect from "react-redux/es/connect/connect";
import * as EmergencyCaseActions from "../../redux/Emergency/actions";
import history from "../../history/index";
import ActionFooter from '../../components/actionFooter';
import ContentContainer from "../../components/ContentContainer";
import EmergencyCaseModal from "../../components/EmergencyCaseModal";


const Search = Input.Search;


class EmergencyCases extends Component{

    showEmergencyCase(emergency) {
        //history.push("/app/users/" + id);
        this.props.actions.showEmergencyCaseModal(true,emergency);
    }

    componentWillMount() {
        //this.props.actions.setActiveStatus("");
        this.props.actions.LoadEmergencyCaseTable({...this.props.infosTableEmergencyCases, search: this.props.EmergencyCaseSearch});
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationEmergencyCases };
        pager.current = pagination.current;

        this.props.actions.setEmergencyCasesTablePage(pager);

        this.props.actions.setEmergencyCasesTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.LoadEmergencyCaseTable({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.EmergencyCaseSearch,
        });
    };

    onChangeSearch = (e) => {
        this.props.actions.setEmergencyCasesSearchText(e.target.value);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.EmergencyCaseModalVisible && !this.props.EmergencyCaseModalVisible && prevProps.savingEmergencyCase && !this.props.savingEmergencyCase)
        {
            this.props.actions.LoadEmergencyCaseTable({...this.props.infosTableEmergencyCases, search: this.props.EmergencyCaseSearch});
        }

    }


    render(){



        const columns = [
            {
                title: '',
                dataIndex: 'id',
                key: 'icon',
                render: text => <Icon type={"alert"}/>,
                width: 50
            }, {
                title: 'CaseID',
                dataIndex: 'CaseID',
                key: 'id',
                sorter: true,
                sortOrder: this.props.infosTableEmergencyCases.sortField === 'CaseID' && this.props.infosTableEmergencyCases.sortOrder,
            }, {
                title: 'Emergency',
                dataIndex: 'EmergencyName',
                key: 'EmergencyName',
                render: text => <span>{text.map((e,i) => { return `${ (i > 0 ? ', ' : '') + e}`})}</span>,
                sorter: true,
                sortOrder: this.props.infosTableEmergencyCases.sortField === 'EmergencyName' && this.props.infosTableEmergencyCases.sortOrder,
            },
            {
                title: 'Case',
                dataIndex: 'CaseName',
                key: 'CaseName',
                sorter: true,
                sortOrder: this.props.infosTableEmergencyCases.sortField === 'CaseName' && this.props.infosTableEmergencyCases.sortOrder,
            }
        ];



        return (
            <React.Fragment>
                <EmergencyCaseModal />
                <ContentContainer head={<Row style={{width: '100%'}}>
                    <Col span={12} className={"HeaderText"}>
                        <Icon type={"alert"} />
                        <span>Emergency Cases</span>
                    </Col>
                    <Col span={12}>
                        <Search onChange={this.onChangeSearch} value={this.props.EmergencyCaseSearch} suffix={<Icon type="close-circle" onClick={() => {this.props.actions.setEmergencyCasesSearchText(''); this.props.actions.LoadEmergencyCaseTable( {...this.props.infosTableEmergencyCases, search: ''});}} />} placeholder={"Search..."} onSearch={() => this.props.actions.LoadEmergencyCaseTable( {...this.props.infosTableEmergencyCases, search: this.props.EmergencyCaseSearch})}  enterButton style={{width: '400px', minHeight: '0px !important', float: 'right'}} />
                    </Col>
                </Row>}>


                    <Table
                        columns={columns}
                        loading={this.props.loadingEmergencyCasesList}
                        dataSource={this.props.EmergencyCases}
                        onRow={(record) => ({
                            onClick: () => {
                                this.showEmergencyCase(record);
                            }
                        })}
                        rowClassName={(record, index) => 'table-clickable'}
                        pagination={this.props.paginationEmergencyCases}
                        onChange={this.handleTableChange}
                    />


                    <ActionFooter>
                        <div className="isoLeft">
                            <Button
                                type="default"
                                htmlType="button"
                                onClick={() => history.goBack()}
                            >
                                <Icon type="left"/>Back
                            </Button>
                        </div>
                        <div className="isoRight">
                            <Button onClick={() => this.showEmergencyCase({})} type="primary">
                                <Icon type="plus"/>Add
                            </Button>
                        </div>
                    </ActionFooter>

                </ContentContainer>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {EmergencyReducer} = state;
    const {EmergencyCases, loadingEmergencyCasesList, paginationEmergencyCases, infosTableEmergencyCases, EmergencyCaseSearch, EmergencyCaseActiveStatus, EmergencyCaseModalVisible, savingEmergencyCase} = EmergencyReducer;
    return {
        EmergencyCases,
        loadingEmergencyCasesList,
        paginationEmergencyCases,
        infosTableEmergencyCases,
        EmergencyCaseSearch,
        EmergencyCaseActiveStatus,
        EmergencyCaseModalVisible,
        savingEmergencyCase
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(EmergencyCaseActions, dispatch)
    };
}




export default connect(mapStateToProps, mapDispatchToProps)(EmergencyCases);