import React, {Component} from "react";
import {Modal, Select} from 'antd';

const { Option } = Select;

class ManageStaffModal extends Component {


   componentDidUpdate(prevProps, prevState, snapshot) {
       if(!prevProps.visible && this.props.visible) {
           this.setState({'selected': this.props.selected || []})
       }
   }


    render() {

        /*
        footer={[
                    <Button key="back" icon={"download"} type="default" onClick={() => window.open(this.props.file.url, "_blank")}>
                        Download
                    </Button>
                    ,
                    <Button key="submit" icon={"close"} type="primary" onClick={this.props.onCancel}>
                        Close
                    </Button>,
                ]}
         */

        return (
            <Modal
                visible={this.props.visible}
                title="Manage Staff"
                onOk={() => this.props.onOk(this.state.selected)}
                onCancel={this.props.onCancel}
                cancelText={"Close"}
                okText={"Apply"}
                width={"750px"}

            >



                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    onChange={(e) => this.setState({'selected': e})}
                    value={this.state?.selected || []}
                >
                    {this.props.AllEmployees?.map((employee, i) =>
                        <Option key={i} value={employee.EmployeeID} >{employee.LastName} {employee.FirstName}</Option>
                    )}
                </Select>



            </Modal>
        );
    }
}



export default ManageStaffModal;
