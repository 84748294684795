import React, {Component} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {connect} from "react-redux";
import Users from '../containers/Users/Users';
import UsersDetail from '../containers/Users/UsersDetail';
import Employees from '../containers/Employees/Employees';
import EmployeesDetail from '../containers/Employees/EmployeesDetail';
import Hospitals from "./Hospitals/Hospitals";
import HospitalsDetail from "./Hospitals/HospitalsDetail";
import FileDownload from './FileDownload';
import EmergencyCases from "./EmergencyCases/EmergencyCases";
import Notification from "./Notification/Push";


class AppRoutes extends Component {

    //<Route path='*' component={NotFound}/>

    render() {

        if(!this.props.idToken) {
            return (
                <Redirect to={{pathname: '/login'}} />
            );
        }

        return (
            <Switch>
                {/*<Route exact path='/app/dashboard' component={Dashboard}/>*/}
                <Route exact path='/app/users' component={Users}/>
                <Route exact path='/app/notification' component={Notification}/>
                <Route exact path='/app/users/:ID' component={UsersDetail}/>
                <Route exact path='/app/employees' component={Employees}/>
                <Route exact path='/app/employees/:ID' component={EmployeesDetail}/>
                <Route exact path='/app/hospitals' component={Hospitals}/>
                <Route exact path='/app/hospitals/:ID' component={HospitalsDetail}/>
                <Route exact path='/app/file/:type/:FileID' component={FileDownload}/>
                <Route exact path='/app/emergencycases' component={EmergencyCases}/>
            </Switch>
        );

    }
}



function mapStateToProps(state) {
    const { AuthReducer } = state;
    const { idToken } = AuthReducer;
    return {
        idToken
    };
}

/*
function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(RoutesActions, dispatch)
    };
}
*/

export default connect(mapStateToProps, null)(AppRoutes);
