import React from 'react';
import AppRoutes from './AppRoutes';
import { Layout } from 'antd';
import {Icon, Menu} from "antd";
import { Link } from 'react-router-dom';
import '../styles/css/App.css';


const { Content, Sider } = Layout;


//  <Sider width={200} style={{ background: '#fff' }}>

function App(props) {
  return (
      <Layout className="App">
          {/*<Header className="header">
              <div className="logo" />
          </Header>*/}
          <Layout>
              <Sider width={235}>
                  <div className="logo" />
                  <Menu
                      mode="inline"
                      theme="dark"
                      defaultSelectedKeys={[props.location.pathname]}

                  >
                      <Menu.Item key={"/app/users"}>
                          <Link to={"/app/users"}>
                              <Icon type={"user"} />
                              <span>Users</span>
                          </Link>
                      </Menu.Item>
                      <Menu.Item key={"/app/notification"}>
                          <Link to={"/app/notification"}>
                              <Icon type={"notification"} />
                              <span>Notification</span>
                          </Link>
                      </Menu.Item>
                      <Menu.Item key={"/app/employees"}>
                          <Link to={"/app/employees"}>
                              <Icon type={"team"} />
                              <span>Staff</span>
                          </Link>
                      </Menu.Item>
                      <Menu.Item key={"/app/hospitals"}>
                          <Link to={"/app/hospitals"}>
                              <Icon type={"medicine-box"} />
                              <span>Hospitals</span>
                          </Link>
                      </Menu.Item>
                      <Menu.Item key={"/app/emergencycases"}>
                          <Link to={"/app/emergencycases"}>
                              <Icon type={"alert"} />
                              <span>Emergency Cases</span>
                          </Link>
                      </Menu.Item>
                      <Menu.Item key={"/logout"}>
                          <Link to={"/logout"}>
                              <Icon type={"logout"} />
                              <span>Logout</span>
                          </Link>
                      </Menu.Item>
                  </Menu>
              </Sider>
              <Content style={{marginLeft: '235px'}}>
                  <AppRoutes />
              </Content>
          </Layout>
      </Layout>
  );
}

export default App;
