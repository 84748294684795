import {combineReducers} from 'redux';
import AuthReducer from './Auth/reducer';
import UserReducer from "./Users/reducer";
import HospitalReducer from './Hospitals/reducer';
import EmergencyReducer from './Emergency/reducer';
import EmployeeReducer from './Employees/reducer';
import NotificationReducer from './Notification/reducer'

const rootReducer = combineReducers({
   AuthReducer,
   UserReducer,
   HospitalReducer,
   EmergencyReducer,
   EmployeeReducer,
   NotificationReducer
});

export default rootReducer;
