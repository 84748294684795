import React, { Component } from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as AuthActions from "../../redux/Auth/actions";
import '../../styles/css/Login.css';
import { Redirect } from 'react-router-dom';



class Logout extends Component {

    componentDidMount() {
            this.props.actions.logout();
    }

    render() {
        return (
            <Redirect to={{pathname: '/login'}} />
        )
    }
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(AuthActions, dispatch)
    };
}


export default connect(null, mapDispatchToProps)(Logout);

