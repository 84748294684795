import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Form, Icon, Input, Button, Row, Col, Spin, Upload, Tabs, Badge, Modal, Table, InputNumber, Checkbox} from 'antd';
import history from '../../history';
import * as HospitalActions from "../../redux/Hospitals/actions";
import * as EmergencyActions from "../../redux/Emergency/actions";
import * as EmployeeActions from "../../redux/Employees/actions";
import ActionFooter from '../../components/actionFooter';
import ContentContainer from "../../components/ContentContainer";
import FileModal from '../../components/FileModal';
import ManageStaffModal from '../../components/ManageStaffModal';
import AddPhotoTypeModal from '../../components/AddPhotoTypeModal';
import '../../styles/css/HospitalsDetail.css';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import DNDScroll from '../../components/DNDScroll';

const FormItem = Form.Item;
const confirm = Modal.confirm;

let dragingIndex = -1;


let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;
//let token = localStorage.getItem('id_token') || null;



const { TabPane } = Tabs;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}


class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };

        let { className } = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />),
        );
    }
}

const rowSource = {
    beginDrag(props) {
        console.log(props);
        DNDScroll.addEventListenerForSidebar("ant-table-body");
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
    endDrag(props) {
        DNDScroll.removeEventListenerForSidebar();
    },
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
);



//let uuid = 0;

class HospitalsDetail extends Component {


    HospitalID = null;
    //unblock = undefined;

    StaffTableColumns = [
        {
            title: '',
            dataIndex: 'EmployeeID',
            key: 'icon',
            render: text =>  <Icon type={"team"} />,
            width: 50
        },
        {
            title: 'Title',
            dataIndex: 'Title',
            key: 'Title',
        },{
            title: 'First Name',
            dataIndex: 'FirstName',
            key: 'FirstName',
        },{
            title: 'Last Name',
            dataIndex: 'LastName',
            key: 'LastName',
        },{
            title: '',
            dataIndex: 'EmployeeID',
            key: 'delete',
            render: text =>  <Icon className={"delete"} type={"minus-circle"} onClick={() => this.removeStaff(text)} />,
            width: 50
        }];

    PhototypesTableColumns = [
        {
            title: '',
            dataIndex: 'Type',
            key: 'icon',
            render: text =>  <Icon type={"mail"} />,
            width: 50
        },
        {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
        },{
            title: 'E-Mail',
            dataIndex: 'EMail',
            key: 'EMail',
        },{
            title: '',
            dataIndex: 'Type',
            key: 'delete',
            render: text =>  <Icon className={"delete"} type={"minus-circle"} onClick={() => this.removePhotoType(text)} />,
            width: 50
        }];


    normMultiFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    normSingleFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList.slice(-1);
    };

    componentWillMount() {
        this.HospitalID = this.props.match.params.ID || null;

        this.props.actions.clearHospitalDetail();
        this.props.form.resetFields();

        this.props.emergencyActions.loadAllEmergencys();
        this.props.employeeActions.loadAllEmployees();


        if(this.HospitalID > 0) {
            this.props.actions.LoadHospitalDetail(this.HospitalID);
            this.props.actions.LoadHospitalImage(this.HospitalID);
        }

    }

    componentWillUnmount() {
       /* if(typeof this.unblock !== 'undefined') {
            this.props.actions.clearHospitalDetail();
            this.unblock();
        }*/
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.savingHospitalDetail && !nextProps.savingHospitalDetail && nextProps.savingHospitalDetailStatus >= 0) {

            if(nextProps.savingHospitalDetailStatus > 0) {
                //this.unblock();
                history.replace("/app/hospitals/"+nextProps.savingHospitalDetailStatus);
            }
            this.props.actions.clearHospitalDetail();
            this.props.form.resetFields();
            this.props.actions.LoadHospitalDetail(this.HospitalID);
            this.props.actions.LoadHospitalImage(this.HospitalID);

        }

        if(this.props.deletingHospital && !nextProps.deletingHospital) {
            history.replace("/app/hospitals");
        }

    }

    componentWillUpdate(nextProps) {

        /*if(this.props.form.isFieldsTouched() && typeof this.unblock === 'undefined')
        {
            this.unblock = history.block(targetLocation => {
                if( window.confirm("Sie verlassen diese Seite ohne zu speichern.") ) {
                    return true;
                } else {
                    return false;
                }
            });
        } else if(typeof this.unblock !== 'undefined' && !this.props.form.isFieldsTouched()) {
            this.unblock();
        }*/
    }




    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }





    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.actions.saveHospital( this.HospitalID, values);
            }
        });
    };


    confirmDelete = () => {
        confirm({
            title: "Delete hospital",
            content: "Do you really want to delete this hospital?",
            okText: "Delete",
            okType: 'danger',
            cancelText: "cancel",
            onOk: () => this.props.actions.deleteHospital(this.HospitalID),
            onCancel() {
            },
        });
    };

    /*previewFile(file, ID) {

        let bodyJSON = {name: file.name, ID};

        let url = BASE_URL+'api/' + API_VERSION + "/Start/Thumbnail";
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type':'application/json', 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': 0
            },
            body: JSON.stringify(bodyJSON),
        })
            .then(res => res.blob())
            .then((blob) => URL.createObjectURL(blob));
    }*/

    previewFile(file) {
        this.props.actions.showPreviewFileModal(true, file);
    }
    previewFileModalOK = (value, FileID) => {
        this.props.actions.showPreviewFileModal(false);
    };
    previewFileModalCancel = () => {
        this.props.actions.showPreviewFileModal(false);
    };

    /*removeContainer = (k) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    addContainer = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(uuid);
        uuid++;

        form.setFieldsValue({
            keys: nextKeys,
        });
    };*/

    /*checkExistingEmail = (e) => {
        this.props.actions.checkHospitalEmail( this.HospitalID,e.target.value );
    };*/


    handlePictureChange = info => {
        if (info.file.status === 'uploading') {
            //this.props.actions.setHospitalsImageLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, image => {
                //this.props.actions.setHospitalsImageLoading(false);
                this.props.actions.setHospitalsImage(image);
            });
        }
        return info;
    };


    checkEmergencyCase(EmergencyCase) {
        let actValue = this.props.form.getFieldValue('EmergencyCases');
        if (actValue.includes(EmergencyCase)) {
            let i = actValue.indexOf(EmergencyCase);
            actValue.splice(i, 1);
        } else {
            actValue.push(EmergencyCase);
        }
        this.props.form.setFieldsValue({'EmergencyCases': actValue});
        //this.props.form.setFields({'EmergencyCases': {value: actValue, errors: []}});
    }


    setStaff = (value) => {
        //let newValues = this.props.form.getFieldValue('Staff').concat(value);
        let newValues = value;
        newValues = newValues.filter(function (item, pos) {return newValues.indexOf(item) === pos});
        this.props.form.setFieldsValue({'Staff': newValues});
        this.props.actions.showStaffModal(false);
    };

    removeStaff = (value) => {
        let newValues = this.props.form.getFieldValue('Staff');
        newValues = newValues.filter(function (item) {return item !== value});
        this.props.form.setFieldsValue({'Staff': newValues});
    };

    addPhotoType = (value) => {
        let newValues = this.props.form.getFieldValue('PhotoTypes').concat([value]);
        this.props.form.setFieldsValue({'PhotoTypes': newValues});
        this.props.actions.showPhotoTypeModal(false);
    };

    removePhotoType = (value) => {
        let newValues = this.props.form.getFieldValue('PhotoTypes');
        newValues = newValues.filter(function (item) {return item.Type !== value});
        this.props.form.setFieldsValue({'PhotoTypes': newValues});
    };

    getTabIcon(emergency) {

        switch (emergency) {
            case "Burns":
                return "fire";
            case "Cardiac/Cardiovascular":
                return "heart";
            case "Environmental":
                return "environment";
            case "Medical":
                return "medicine-box";
            case "Neurosciences":
                return "thunderbolt";
            case "Obstetrics/Gynecology":
                return "wifi";
            case "Pediatrics":
                return "smile";
            case "Trauma":
                return "skin";

            default:
                return "info";

        }


    }


   /* removeStaff = (k) => {
        const { form } = this.props;
        const StaffKeys = form.getFieldValue('StaffKeys');
        form.setFieldsValue({
            StaffKeys: StaffKeys.filter(key => key !== k),
        });
    };

    addStaff = () => {
        const { form } = this.props;
        const StaffKeys = form.getFieldValue('StaffKeys');
        const nextKeys = StaffKeys.concat(uuid);
        uuid++;

        form.setFieldsValue({
            StaffKeys: nextKeys,
        });
    };*/




    moveRow = (dragIndex, hoverIndex) => {
        let StaffData = this.props.form.getFieldValue('Staff');
        const dragRow = StaffData[dragIndex];
        let newStaffData = update(StaffData, {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]]
            });
        this.props.form.setFieldsValue({'Staff': newStaffData});
    };


    render() {


        const { getFieldDecorator, getFieldsError } = this.props.form;

        getFieldDecorator('EmergencyCases', { initialValue: this.props.Hospital.EmergencyCases  });
        getFieldDecorator('Staff', { initialValue: this.props.Hospital.Staff });
        getFieldDecorator('PhotoTypes', { initialValue: this.props.Hospital.PhotoTypes });

        const Staff = this.props.form.getFieldValue('Staff');

        const PhotoTypes = this.props.form.getFieldValue('PhotoTypes');


        /*getFieldDecorator('StaffKeys', { initialValue: this.props.Hospital.Staff ? this.props.Hospital.Staff.map((c, i) => {return i;} ) : []} );
        const StaffKeys = this.props.form.getFieldValue('StaffKeys');
        if(this.props.Hospital.Staff && uuid < this.props.Hospital.Staff.length) { uuid = this.props.Hospital.Staff.length; }*/

        /*
        footer={[
                            <Button key="back" icon={"download"} type="default" onClick={() => window.open(this.props.file.url, "_blank")}>
                                Download
                            </Button>
                            ,
                            <Button key="submit" icon={"close"} type="primary" onClick={this.props.onCancel}>
                                Close
                            </Button>,
                        ]}
                        */

        return (
                <React.Fragment>

                <ManageStaffModal visible={this.props.staffModalVisible} onOk={this.setStaff} onCancel={() => this.props.actions.showStaffModal(false)} AllEmployees={this.props.AllEmployees} selected={Staff}/>

                <AddPhotoTypeModal visible={this.props.photoTypeModalVisible} onOk={this.addPhotoType} onCancel={() => this.props.actions.showPhotoTypeModal(false)}/>

                <FileModal visible={this.props.previewFileModalVisible} file={this.props.previewFileModalFile} onOk={this.previewFileModalOK} onCancel={this.previewFileModalCancel}/>



                <Form id={"form"} layout="vertical" onSubmit={this.handleSubmit}>
                    <Spin spinning={this.props.loadingHospitalDetail}>
                        <div style={{display: 'flex'}}>

                            <ContentContainer head={<div className={"HeaderText"}>
                                <Icon type={"medicine-box"} />
                                <span>{this.HospitalID > 0 ? this.props.Hospital.Name : "Create Hospital"}</span>
                            </div>} borderRight>





                                    <Row>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={22}>

                                                    <FormItem>
                                                        <div className="dropbox">
                                                                {getFieldDecorator('Pictures', {
                                                                    valuePropName: 'fileList',
                                                                    getValueFromEvent: this.normSingleFile,
                                                                    initialValue: this.props.Hospital.Pictures,
                                                                })(
                                                                    <Upload name="files"
                                                                        action={BASE_URL+'api/'+API_VERSION+"/hospitals/"+this.HospitalID+"/Upload"}
                                                                        headers={{authorization: `Bearer ${this.props.idToken}`}}
                                                                        accept={".jpg,.tif,.png,.pdf"}
                                                                        listType={'picture-card'}
                                                                        onPreview={(file) => this.previewFile(file)}
                                                                        onChange={this.handlePictureChange}
                                                                        multiple={false}
                                                                        showUploadList={false}
                                                                    >
                                                                        {this.props.HospitalImage ?
                                                                            <img src={this.props.HospitalImage} style={{height: '300px', width: '100%', objectFit: 'cover', objectPosition: 'bottom'}} />
                                                                        :
                                                                            <div style={{height: '200px', width: '100%'}}><span style={{display: 'block', marginTop: '150px', textAlign: 'center'}}> {this.props.loadingHospitalImage ? <Icon style={{fontSize: '50px'}} type="loading" /> : 'No Picture available' } </span></div>
                                                                        }
                                                                    </Upload>
                                                                )}
                                                        </div>
                                                    </FormItem>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}><FormItem
                                                    label={"Name"}
                                                >
                                                    {getFieldDecorator('Name', {
                                                        rules: [{required: true, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.Name
                                                    })(
                                                        <Input/>
                                                    )}
                                                </FormItem></Col>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}>
                                                    <FormItem
                                                    label={"Street"}
                                                >
                                                    {getFieldDecorator('Street', {
                                                        rules: [{required: false, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.Street
                                                    })(
                                                        <Input/>
                                                    )}
                                                </FormItem>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}><FormItem
                                                    label={"Zip"}
                                                >
                                                    {getFieldDecorator('ZIP', {
                                                        rules: [{required: false, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.ZIP
                                                    })(
                                                        <Input/>
                                                    )}
                                                </FormItem></Col>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}><FormItem
                                                    label={"City"}
                                                >
                                                    {getFieldDecorator('City', {
                                                        rules: [{required: false, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.City
                                                    })(
                                                        <Input/>
                                                    )}
                                                </FormItem></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}><FormItem
                                                    label={"Phone"}
                                                >
                                                    {getFieldDecorator('Phone', {
                                                        rules: [{required: false, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.Phone
                                                    })(
                                                        <Input/>
                                                    )}
                                                </FormItem></Col>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}><FormItem
                                                    label={"Medical command phone"}
                                                >
                                                    {getFieldDecorator('MedicalCommandPhone', {
                                                        rules: [{required: false, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.MedicalCommandPhone
                                                    })(
                                                        <Input/>
                                                    )}
                                                </FormItem></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}><FormItem
                                                    label={"Latitude"}
                                                >
                                                    {getFieldDecorator('LAT', {
                                                        rules: [{required: false, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.LAT
                                                    })(
                                                        <InputNumber min={-90} max={90} step={0.0000001} style={{width: '100%'}} />
                                                    )}
                                                </FormItem></Col>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}><FormItem
                                                    label={"Longitude"}
                                                >
                                                    {getFieldDecorator('LONG', {
                                                        rules: [{required: false, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.LONG
                                                    })(
                                                        <InputNumber min={-180} max={180} step={0.00000001} style={{width: '100%'}} />
                                                    )}
                                                </FormItem></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}><FormItem
                                                    label={"Radio frequencies"}
                                                >
                                                    {getFieldDecorator('RadioFrequencies', {
                                                        rules: [{required: false, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.RadioFrequencies
                                                    })(
                                                        <Input/>
                                                    )}
                                                </FormItem></Col>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}>
                                                    <FormItem
                                                    label={"E-Mail"}
                                                    hasFeedback={true}
                                                >
                                                    {getFieldDecorator('EMail', {
                                                        rules: [{ type: 'email', message: 'please enter a valid e-mail address'},
                                                            {required: false, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.EMail
                                                    })(
                                                        <Input type={"email"} />
                                                    )}
                                                </FormItem></Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={20}><FormItem
                                                    label={""}
                                                >
                                                    {getFieldDecorator('AHNFacility', {
                                                        rules: [{required: false, message: 'please fill this information'}],
                                                        initialValue: this.props.Hospital.AHNFacility,
                                                        valuePropName: 'checked'
                                                    })(
                                                        <Checkbox>AHN Facility</Checkbox>
                                                    )}
                                                </FormItem></Col>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            &nbsp;
                                        </Col>
                                    </Row>

                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={""}
                                            >
                                                {getFieldDecorator('HideInApp', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.Hospital.HideInApp,
                                                    valuePropName: 'checked'
                                                })(
                                                    <Checkbox>Hide in App</Checkbox>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        &nbsp;
                                    </Col>
                                </Row>

                                    {/*<Row>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={22}>
                                                    <FormItem
                                                        label="Picture"
                                                    >
                                                        <div className="dropbox">
                                                            {getFieldDecorator('Pictures', {
                                                                valuePropName: 'fileList',
                                                                getValueFromEvent: this.normSingleFile,
                                                                initialValue: this.props.Hospital.Pictures,
                                                            })(
                                                                <Upload name="files"
                                                                        action={BASE_URL+'api/'+API_VERSION+"/hospitals/"+this.HospitalID+"/Upload"}
                                                                        headers={{authorization: `Bearer ${token}`}}
                                                                        accept={".jpg,.tif,.png,.pdf"}
                                                                        listType={'picture-card'}
                                                                        onPreview={(file) => this.previewFile(file)}
                                                                        multiple={false}
                                                                        showUploadList={true}
                                                                >
                                                                    <Icon type={"upload"} />
                                                                    {/*<p className="ant-upload-drag-icon">
                                                                    <Icon type="inbox" />
                                                                </p>
                                                                <p className="ant-upload-text">Hier klicken oder Datei in diesen Bereich ziehen</p>
                                                                <p className="ant-upload-hint">Es werden nur .JPG, .TIF, .PNG und .PDF Dateien unterstützt.</p>* /}
                                                                </Upload>
                                                            )}
                                                        </div>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>*/}





                        </ContentContainer>

                        <ContentContainer head={<div className={"HeaderText"}>
                                    <Icon type={"alert"} />
                                    <span>Emergency Cases</span>
                                </div>}>
                                    {/*<Table
                                columns={columnsDeviceList}
                                dataSource={this.props.Devices} />*/}





                            {/*this.props.allEmergencys.map( emergency =>
                                <div className={"EmergencyBox"}>
                                    <span className={"EmergencyTitle"}>{emergency.Emergency}</span>

                                    <div className={"EmergencyContent"}>
                                        {emergency.Cases.map(eCase =>

                                            <span onClick={() => this.checkEmergencyCase(eCase.CaseID)}
                                                  className={`EmergencyItem ${Array.isArray(this.props.form.getFieldValue('EmergencyCases')) && this.props.form.getFieldValue('EmergencyCases').includes(eCase.CaseID) ? ' selected ' : ''}`}
                                            >
                                                {eCase.CaseName}
                                            </span>

                                        )}
                                    </div>
                                </div>
                            )*/}


                            <Tabs tabPosition="left">

                                {this.props.allEmergencys.map( emergency =>
                                    <TabPane tab={

                                        <span>
                                            <Badge count={emergency.Cases.filter(c => (this.props.form.getFieldValue('EmergencyCases') ||[]).includes(c.CaseID)).length} /> &nbsp;
                                            {emergency.Emergency}
                                            &nbsp;
                                            <Icon type={this.getTabIcon(emergency.Emergency)} />

                                        </span>} key={emergency.Emergency}>
                                <div className={"EmergencyBox"}>

                                    <div className={"EmergencyContent"}>
                                        {emergency.Cases.map(eCase =>

                                            <span key={eCase.CaseID} onClick={() => this.checkEmergencyCase(eCase.CaseID)}
                                                  className={`EmergencyItem ${Array.isArray(this.props.form.getFieldValue('EmergencyCases')) && this.props.form.getFieldValue('EmergencyCases').includes(eCase.CaseID) ? ' selected ' : ''}`}
                                            >
                                                {eCase.CaseName}
                                            </span>

                                        )}
                                    </div>
                                </div>
                                    </TabPane>
                            )}

                            </Tabs>

                        </ContentContainer>
                        </div>
                        {/*<ContentContainer head={<Row>
                            <Col sm={12}><div className={"HeaderText"}>
                                <Icon type={"alert"} />
                                <span>Staff</span>
                            </div></Col>
                            <Col sm={12} style={{textAlign: 'right'}}><Button htmlType={"button"} onClick={() => this.props.actions.showStaffModal(true)} icon={"plus"}>Add Staff</Button></Col>
                        </Row>

                            }>*/}



                        <ContentContainer head={

                            <div className={"HeaderText"}>
                            <Icon type={"team"} />
                            <span>Staff</span>
                        </div>}>

                            <Icon type={"info-circle"} style={{color: '#40BD7D'}} /> <span > Drag row to sort order</span>

                             <div style={{marginBottom: '15px', textAlign: 'right'}}>
                                <Button htmlType={"button"} onClick={() => this.props.actions.showStaffModal(true)} icon={"edit"}>Manage Staff</Button>
                            </div>

                            {/* dataSource={this.props.AllEmployees.filter(empl => Staff?.includes(empl.EmployeeID))} */}

                            <DndProvider backend={HTML5Backend}>
                                <Table
                                    columns={this.StaffTableColumns}
                                    dataSource={Staff?.map(S => this.props.AllEmployees.find(empl => empl.EmployeeID === S))}
                                    components={
                                        {
                                            body: {
                                                row: DragableBodyRow,
                                            },
                                        }
                                    }
                                    onRow={(record, index) => ({
                                        index,
                                        moveRow: this.moveRow,
                                    })}
                                    pagination={false}
                                    scroll={{ y: 500 }}
                                />
                            </DndProvider>

                        </ContentContainer>


                        <ContentContainer head={
                            <div className={"HeaderText"}>
                                <Icon type={"mail"} />
                                <span>Photo-Types</span>
                            </div>}>



                            <div style={{marginBottom: '15px', textAlign: 'right'}}>
                                <Button htmlType={"button"} onClick={() => this.props.actions.showPhotoTypeModal(true)} icon={"plus"}>Add Photo-Type</Button>
                            </div>


                            <Table
                                columns={this.PhototypesTableColumns}
                                dataSource={PhotoTypes}
                                pagination={false}
                                scroll={{ y: 500 }}
                            />

                        </ContentContainer>
                    </Spin>
                </Form>



                    <ActionFooter>
                    <div className="isoLeft">
                        <Button
                            type="default"
                            htmlType="button"
                            onClick={() => history.goBack()}
                        >
                            <Icon type="left"/>Back
                        </Button>
                    </div>
                    <div className="isoRight">
                        <div className={"ButtonGroup"}>
                            {this.HospitalID && this.HospitalID > 0 ? <Button
                                type={"danger"}
                                icon={"delete"}
                                onClick={this.confirmDelete}
                                disabled={this.props.deletingHospital}
                                loading={this.props.deletingHospital}
                            >
                                Delete hospital
                            </Button> : ''}
                            <Button
                                type="primary"
                                htmlType="submit"
                                form={"form"}
                                icon={"check"}
                                loading={this.props.savingHospitalDetail}
                                disabled={(this.hasErrors(getFieldsError()) || this.props.savingHospitalDetail )}
                            >Save</Button>
                        </div>

                    </div>
                </ActionFooter>
                </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { HospitalReducer, EmergencyReducer, EmployeeReducer, AuthReducer } = state;
    const { Hospital, loadingHospitalImage, HospitalImage, savingHospitalDetail, savingHospitalDetailStatus, loadingHospitalDetail, previewFileModalVisible, previewFileModalFile, deletingHospital, staffModalVisible, photoTypeModalVisible } = HospitalReducer;
    const { allEmergencys } = EmergencyReducer;
    const { AllEmployees } = EmployeeReducer;
    const { idToken } = AuthReducer;
    return {
        Hospital,
        loadingHospitalImage,
        HospitalImage,
        savingHospitalDetail,
        savingHospitalDetailStatus,
        loadingHospitalDetail,

        deletingHospital,

        AllEmployees,

        previewFileModalVisible,
        previewFileModalFile,
        allEmergencys,
        staffModalVisible,
        photoTypeModalVisible,
        idToken,

    };
}

function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(HospitalActions, dispatch),
        emergencyActions: bindActionCreators(EmergencyActions, dispatch),
        employeeActions: bindActionCreators(EmployeeActions, dispatch)
    };
}

const HospitalEditForm = Form.create()(HospitalsDetail);

export default connect(mapStateToProps, mapDispatchToProps)(HospitalEditForm);
