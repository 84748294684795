import NotificationActions from "./actions";

const initState = {

    Notifications: [],
    loadingNotificationsList: false,
    paginationNotifications: {},
    infosTableNotifications: {sortField: 'Created', sortOrder: 'descend'},
    NotificationsSearch: "",

    NewNotificationModalVisible: false,
    savingNewNotification: false,
};

export default function NotificationReducer(state = initState, action) {
  switch (action.type) {

      case NotificationActions.LOAD_TABLE_NOTIFICATION_REQUEST:
          return Object.assign({}, state, {
              loadingNotificationsList: true
          });
      case NotificationActions.LOAD_TABLE_NOTIFICATION_SUCCESS:
          const pagination = { ...state.paginationNotifications };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = action.response.data.Count;

          return Object.assign({}, state, {
              Notifications: action.response.data.Notifications,
              loadingNotificationsList: false,
              paginationNotifications: pagination
          });
      case NotificationActions.LOAD_TABLE_NOTIFICATION_ERROR:
          return Object.assign({}, state, {
              loadingNotificationsList: false
          });
      case NotificationActions.SET_NOTIFICATION_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationNotifications: action.pager
          });
      case NotificationActions.SET_NOTIFICATION_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableNotifications: action.infos
          });
      case NotificationActions.SET_NOTIFICATION_SEARCH_TEXT:
          return Object.assign({}, state, {
              NotificationSearch: action.search,
              paginationNotifications: {current: 1},
              infosTableNotifications: {}
          });



      case NotificationActions.SHOW_NEW_NOTIFICATION_MODAL:
          return Object.assign({}, state, {
              NewNotificationModalVisible: action.show,
          });


    case NotificationActions.SEND_NOTIFICATION_REQUEST:
        return Object.assign({}, state, {
            savingNewNotification: true,
        });
      case NotificationActions.SEND_NOTIFICATION_SUCCESS:
          return Object.assign({}, state, {
              savingNewNotification: false,
              NewNotificationModalVisible: false
          });
      case NotificationActions.SEND_NOTIFICATION_ERROR:
          return Object.assign({}, state, {
              savingNewNotification: false
          });
    default:
      return state;
  }
}

