import { CALL_API } from '../../middleware/api';

const actions = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT: 'LOGOUT',
    PW_CHANGE_REQUEST: 'PW_CHANGE_REQUEST',
    PW_CHANGE_SUCCESS: 'PW_CHANGE_SUCCESS',
    PW_CHANGE_ERROR: 'PW_CHANGE_ERROR',
};

export default actions;


export function login(payload) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            postData: payload,
            endpoint: 'auth/web/login',
            types: [actions.LOGIN_REQUEST, actions.LOGIN_SUCCESS, actions.LOGIN_ERROR]
        }
    };
}


export function savePasswordChange(password, RecoverToken) {

    return {
        [CALL_API]: {
            reqType: 'POST',
            postData: password,
            endpoint: 'auth/recover/'+RecoverToken,
            types: [actions.PW_CHANGE_REQUEST, actions.PW_CHANGE_SUCCESS, actions.PW_CHANGE_ERROR]
        }
    };
}


/*
function setLogout() {
    return {
        type: actions.LOGOUT
    };
}*/

export function logout() {
    localStorage.removeItem('id_token');
    return {
        type: actions.LOGOUT
    };
}