import { CALL_API } from '../../middleware/api';


const EmployeeActions = {

    LOAD_TABLE_REQUEST: 'LOAD_TABLE_REQUEST',
    LOAD_TABLE_SUCCESS: 'LOAD_TABLE_SUCCESS',
    LOAD_TABLE_ERROR: 'LOAD_TABLE_ERROR',
    LOAD_EMPLOYEE_DETAIL_REQUEST: 'LOAD_EMPLOYEE_DETAIL_REQUEST',
    LOAD_EMPLOYEE_DETAIL_SUCCESS: 'LOAD_EMPLOYEE_DETAIL_SUCCESS',
    LOAD_EMPLOYEE_DETAIL_ERROR: 'LOAD_EMPLOYEE_DETAIL_ERROR',

    SAVE_EMPLOYEE_DETAIL_REQUEST: 'SAVE_EMPLOYEE_DETAIL_REQUEST',
    SAVE_EMPLOYEE_DETAIL_SUCCESS: 'SAVE_EMPLOYEE_DETAIL_SUCCESS',
    SAVE_EMPLOYEE_DETAIL_ERROR: 'SAVE_EMPLOYEE_DETAIL_ERROR',

    CLEAR_EMPLOYEE_DETAIL: 'CLEAR_EMPLOYEE_DETAIL',

    SET_EMPLOYEE_TABLE_PAGE: 'SET_EMPLOYEE_TABLE_PAGE',
    SET_EMPLOYEE_TABLE_INFO: 'SET_EMPLOYEE_TABLE_INFO',
    SET_EMPLOYEE_SEARCH_TEXT: 'SET_EMPLOYEE_SEARCH_TEXT',


    DELETE_EMPLOYEE_REQUEST: 'DELETE_EMPLOYEE_REQUEST',
    DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
    DELETE_EMPLOYEE_ERROR: 'DELETE_EMPLOYEE_ERROR',

    LOAD_ALL_EMPLOYEE_REQUEST: 'LOAD_ALL_EMPLOYEE_REQUEST',
    LOAD_ALL_EMPLOYEE_SUCCESS: 'LOAD_ALL_EMPLOYEE_SUCCESS',
    LOAD_ALL_EMPLOYEE_ERROR: 'LOAD_ALL_EMPLOYEE_ERROR'
};

export default EmployeeActions;


export function LoadEmployeeDetail(employeeID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'employees/' + employeeID,
            types: [EmployeeActions.LOAD_EMPLOYEE_DETAIL_REQUEST, EmployeeActions.LOAD_EMPLOYEE_DETAIL_SUCCESS, EmployeeActions.LOAD_EMPLOYEE_DETAIL_ERROR]
        }
    };
}


export function deleteEmployee(employeeID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'employees/' + employeeID,
            types: [EmployeeActions.DELETE_EMPLOYEE_REQUEST, EmployeeActions.DELETE_EMPLOYEE_SUCCESS, EmployeeActions.DELETE_EMPLOYEE_ERROR]
        }
    };
}

export function loadAllEmployees() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'employees',
            types: [EmployeeActions.LOAD_ALL_EMPLOYEE_REQUEST, EmployeeActions.LOAD_ALL_EMPLOYEE_SUCCESS, EmployeeActions.LOAD_ALL_EMPLOYEE_ERROR]
        }
    };
}


export function LoadEmployeeTable(params= {}) {

    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'employees/table',
            postData: {
                results: 10,
                ...params,
            },
            types: [EmployeeActions.LOAD_TABLE_REQUEST, EmployeeActions.LOAD_TABLE_SUCCESS, EmployeeActions.LOAD_TABLE_ERROR]
        }
    };
}


export function saveEmployee(EmployeeID, values) {
    let type = "POST";
    let url = "employees";

    if(EmployeeID && EmployeeID > 0) {
        type = "PUT";
        url = "employees/"+EmployeeID;
    }

    return {
        [CALL_API]: {
            reqType: type,
            endpoint: url,
            postData: values,
            types: [EmployeeActions.SAVE_EMPLOYEE_DETAIL_REQUEST,
                EmployeeActions.SAVE_EMPLOYEE_DETAIL_SUCCESS,
                EmployeeActions.SAVE_EMPLOYEE_DETAIL_ERROR]
        }
    };
}



export function clearEmployeeDetail() {
    return {
        type: EmployeeActions.CLEAR_EMPLOYEE_DETAIL
    }
}

export function setEmployeesTablePage(pager) {
    return {
        type: EmployeeActions.SET_EMPLOYEE_TABLE_PAGE,
        pager
    };
}

export function setEmployeesTableInfo(infos) {
    return {
        type: EmployeeActions.SET_EMPLOYEE_TABLE_INFO,
        infos
    };
}

export function setEmployeesSearchText(search) {
    return {
        type: EmployeeActions.SET_EMPLOYEE_SEARCH_TEXT,
        search
    };
}
