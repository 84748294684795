import React, { Component } from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as AuthActions from "../../redux/Auth/actions";
import '../../styles/css/Login.css';
import { Form, Icon, Input, Button } from 'antd';
import { Redirect } from 'react-router-dom';


function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}


class Recover extends Component {

    RecoverToken = "";

    state = {
        confirmDirty: false
    };


    componentWillMount() {
        this.RecoverToken = this.props.match.params.RecoverToken || null;
    }


    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback(this.props.strings.PasswordsNotMatch);
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['password2'], { force: true });
        }
        callback();
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                //this.props.actions.saveSettings(values);
                this.props.form.resetFields();
                this.props.actions.savePasswordChange({password: values.password}, this.RecoverToken);
            }
        });
    };



    render() {

        const { getFieldDecorator, getFieldsError } = this.props.form;


        return (
            <div className={"login-container"}>
                <div className={"login-wrapper"}>
                    <div className="login-box">
                        <div className={"login-header"}>
                            <h2>PASSWORD RECOVERY</h2>
                            <span>EMS Field Partner password recovery</span>
                        </div>

                        { this.props.passwordChangeSuccess ? <div style={{textAlign: 'center'}}><span>Your new password is successfully saved!</span></div> :

                        <Form onSubmit={this.handleSubmit} className={"form-wrapper"}>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{
                                        required: true, message: 'Please enter a password'
                                    }, {
                                        validator: this.validateToNextPassword,
                                    }, {
                                        min: 8,
                                        message: 'Your password must consist of at least 8 characters'
                                    }],
                                })(
                                    <Input
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password"
                                        placeholder="Password"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password2', {
                                    rules: [{
                                        required: true, message: 'Please enter a password'
                                    }, {
                                        validator: this.compareToFirstPassword,
                                    }, {
                                        min: 8,
                                        message: 'Your password must consist of at least 8 characters'
                                    }],
                                })(
                                    <Input
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password"
                                        placeholder="Retype password"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError()) || this.props.passwordChangeSaving} loading={this.props.passwordChangeSaving}>
                                    Save new password
                                </Button>
                            </Form.Item>
                        </Form> }
                    </div>
                </div>
            </div>
        )
    }
}




function mapStateToProps(state) {
    const { AuthReducer } = state;
    const { passwordChangeSaving, passwordChangeSuccess } = AuthReducer;
    return {
        passwordChangeSaving,
        passwordChangeSuccess
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(AuthActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Recover));

