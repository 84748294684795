import React, { Component } from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as AuthActions from "../../redux/Auth/actions";
import '../../styles/css/Login.css';
import { Form, Icon, Input, Button } from 'antd';
import { Redirect } from 'react-router-dom';


function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}


class Login extends Component {

    componentDidMount() {
        // To disabled submit button at the beginning.
      //  this.props.form.validateFields();

        if(this.props.match.params.logout) {
            this.props.actions.logout();
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.actions.login(values);
            }
        });
    };



    render() {

        const { getFieldDecorator, getFieldsError } = this.props.form;

        if(this.props.idToken && !this.props.match.params.logout) {
            return <Redirect to={{pathname: '/app/hospitals'}} />
        }

        return (
            <div className={"login-container"}>
                <div className={"login-wrapper"}>
                    <div className="login-box">
                        <div className={"login-header"}>
                            <h2>LOG IN</h2>
                            <span>EMS Field Partner login</span>
                        </div>
                        <Form onSubmit={this.handleSubmit} className={"form-wrapper"}>
                            <Form.Item>
                                {getFieldDecorator('username', {
                                    rules: [{ required: true, message: 'Please enter a username' }],
                                })(
                                    <Input
                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="Username"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please enter a password' }],
                                })(
                                    <Input
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password"
                                        placeholder="Password"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError()) || this.props.loadingLogin} loading={this.props.loadingLogin}>
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}




function mapStateToProps(state) {
    const { AuthReducer } = state;
    const { loadingLogin, idToken } = AuthReducer;
    return {
        loadingLogin,
        idToken
    };
}


function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(AuthActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Login));

