import UserActions from "./actions";

const initState = {
    Users: [],
    User: {},
    Devices: [],
    editAllowed: false,
    loadingUsersList: false,
    paginationUsers: {},
    infosTableUsers: {sortField: 'UserID', sortOrder: 'descend'},
    UserSearch: "",

    deletingUser: false,

    UserEdit: false,
    savingUserDetail: false,
    savingUserDetailStatus: 0,
    loadingUserDetail: false,

    UserActiveStatus: "",

    UserEmailValidateStatus: undefined
};

export default function UserReducer(state = initState, action) {
  switch (action.type) {
    case UserActions.LOAD_TABLE_REQUEST:
        return Object.assign({}, state, {
            loadingUsersList: true
        });
    case UserActions.LOAD_TABLE_SUCCESS:
        const pagination = { ...state.paginationUsers };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = action.response.data.Count;

        return Object.assign({}, state, {
            Users: action.response.data.Users,
            editAllowed: action.response.data.editAllowed,
            loadingUsersList: false,
            paginationUsers: pagination
        });
      case UserActions.LOAD_TABLE_ERROR:
          return Object.assign({}, state, {
              loadingUsersList: false
          });
      case UserActions.SET_USER_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationUsers: action.pager
          });
      case UserActions.SET_USER_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableUsers: action.infos
          });
      case UserActions.SET_USER_SEARCH_TEXT:
          return Object.assign({}, state, {
              UserSearch: action.search,
              paginationUsers: {current: 1},
              infosTableUsers: {}
          });
      case UserActions.SET_ACTIVE_STATUS:
          return Object.assign({}, state, {
              UserActiveStatus: action.activeStatus,
              paginationUsers: {current: 1},
              infosTableUsers: {}
          });
      case UserActions.LOAD_USER_DETAIL_REQUEST:
          return Object.assign({}, state, {
              loadingUserDetail: true
          });
      case UserActions.LOAD_USER_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              User: action.response.data.User,
              Devices: action.response.data.Devices,
              editAllowed: action.response.data.editAllowed,
              loadingUserDetail: false
          });
      case UserActions.LOAD_USER_DETAIL_ERROR:
          return Object.assign({}, state, {
              loadingUserDetail: false
          });

      case UserActions.SAVE_USER_DETAIL_REQUEST:
          return Object.assign({}, state, {
              savingUserDetail: true,
              savingUserDetailStatus: -2
          });
      case UserActions.SAVE_USER_DETAIL_SUCCESS:
          let UserID = 0;
          if(action.response.data.UserID) {
              UserID = action.response.data.UserID;
          }
          return Object.assign({}, state, {
              savingUserDetail: false,
              savingUserDetailStatus: UserID
          });
      case UserActions.SAVE_USER_DETAIL_ERROR:
          return Object.assign({}, state, {
              savingUserDetail: false,
              savingUserDetailStatus: -1
          });
      case UserActions.DELETE_USERS_DEVICES_REQUEST:
          return Object.assign({}, state, {
              deletingUserDevices: true,
          });
      case UserActions.DELETE_USERS_DEVICES_SUCCESS:
      case UserActions.DELETE_USERS_DEVICES_ERROR:
          return Object.assign({}, state, {
              deletingUserDevices: false,
          });
      case UserActions.CLEAR_USER_DETAIL:
          return Object.assign({}, state, {
              User: {},
              Devices: []
          });

      case UserActions.CHECK_USER_EMAIL_REQUEST:
          return Object.assign({}, state, {
              UserEmailValidateStatus: 'validating'
          });
      case UserActions.CHECK_USER_EMAIL_SUCCESS:
          return Object.assign({}, state, {
              UserEmailValidateStatus: undefined
          });
      case UserActions.CHECK_USER_EMAIL_ERROR:
          return Object.assign({}, state, {
              UserEmailValidateStatus: 'error'
          });


      case UserActions.DELETE_USER_REQUEST:
          return Object.assign({}, state, {
              deletingUser: true
          });
      case UserActions.DELETE_USER_SUCCESS:
          return Object.assign({}, state, {
              deletingUser: false
          });
      case UserActions.DELETE_USER_ERROR:
          return Object.assign({}, state, {
              deletingUser: false
          });
    default:
      return state;
  }
}

