import { CALL_API } from '../../middleware/api';


const HospitalActions = {

    LOAD_TABLE_REQUEST: 'LOAD_TABLE_REQUEST',
    LOAD_TABLE_SUCCESS: 'LOAD_TABLE_SUCCESS',
    LOAD_TABLE_ERROR: 'LOAD_TABLE_ERROR',
    LOAD_HOSPITAL_DETAIL_REQUEST: 'LOAD_HOSPITAL_DETAIL_REQUEST',
    LOAD_HOSPITAL_DETAIL_SUCCESS: 'LOAD_HOSPITAL_DETAIL_SUCCESS',
    LOAD_HOSPITAL_DETAIL_ERROR: 'LOAD_HOSPITAL_DETAIL_ERROR',

    SAVE_HOSPITAL_DETAIL_REQUEST: 'SAVE_HOSPITAL_DETAIL_REQUEST',
    SAVE_HOSPITAL_DETAIL_SUCCESS: 'SAVE_HOSPITAL_DETAIL_SUCCESS',
    SAVE_HOSPITAL_DETAIL_ERROR: 'SAVE_HOSPITAL_DETAIL_ERROR',

    CLEAR_HOSPITAL_DETAIL: 'CLEAR_HOSPITAL_DETAIL',

    SET_HOSPITAL_TABLE_PAGE: 'SET_HOSPITAL_TABLE_PAGE',
    SET_HOSPITAL_TABLE_INFO: 'SET_HOSPITAL_TABLE_INFO',
    SET_HOSPITAL_SEARCH_TEXT: 'SET_HOSPITAL_SEARCH_TEXT',


    SHOW_PREVIEW_FILE_MODAL: 'SHOW_PREVIEW_FILE_MODAL',
    LOAD_HOSPITAL_IMAGE_REQUEST: 'LOAD_HOSPITAL_IMAGE_REQUEST',
    LOAD_HOSPITAL_IMAGE_SUCCESS: 'LOAD_HOSPITAL_IMAGE_SUCCESS',
    LOAD_HOSPITAL_IMAGE_ERROR: 'LOAD_HOSPITAL_IMAGE_ERROR',

    SET_HOSPITAL_IMAGE_LOADING: 'SET_HOSPITAL_IMAGE_LOADING',
    SET_HOSPITAL_IMAGE: 'SET_HOSPITAL_IMAGE',

    DELETE_HOSPITAL_REQUEST: 'DELETE_HOSPITAL_REQUEST',
    DELETE_HOSPITAL_SUCCESS: 'DELETE_HOSPITAL_SUCCESS',
    DELETE_HOSPITAL_ERROR: 'DELETE_HOSPITAL_ERROR',

    SHOW_STAFF_MODAL: 'SHOW_STAFF_MODAL',
    SHOW_PHOTOTYPE_MODAL: 'SHOW_PHOTOTYPE_MODAL'
};

export default HospitalActions;


export function LoadHospitalDetail(hospitalID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'hospitals/' + hospitalID,
            types: [HospitalActions.LOAD_HOSPITAL_DETAIL_REQUEST, HospitalActions.LOAD_HOSPITAL_DETAIL_SUCCESS, HospitalActions.LOAD_HOSPITAL_DETAIL_ERROR]
        }
    };
}

export function LoadHospitalImage(hospitalID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'hospitals/' + hospitalID + '/image',
            types: [HospitalActions.LOAD_HOSPITAL_IMAGE_REQUEST, HospitalActions.LOAD_HOSPITAL_IMAGE_SUCCESS, HospitalActions.LOAD_HOSPITAL_IMAGE_ERROR]
        }
    };
}

export function deleteHospital(hospitalID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'hospitals/' + hospitalID,
            types: [HospitalActions.DELETE_HOSPITAL_REQUEST, HospitalActions.DELETE_HOSPITAL_SUCCESS, HospitalActions.DELETE_HOSPITAL_ERROR]
        }
    };
}




/*export function LoadTable() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'hospitals',
            types: [HospitalActions.LOAD_TABLE_REQUEST, HospitalActions.LOAD_TABLE_SUCCESS, HospitalActions.LOAD_TABLE_ERROR]
        }
    };
}*/

export function LoadHospitalTable(params= {}) {

    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'hospitals/table',
            postData: {
                results: 10,
                ...params,
            },
            types: [HospitalActions.LOAD_TABLE_REQUEST, HospitalActions.LOAD_TABLE_SUCCESS, HospitalActions.LOAD_TABLE_ERROR]
        }
    };
}


/*
export function deleteDevices(hospitalID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'hospitals/'+hospitalID+"/devices",
            types: [HospitalActions.DELETE_HOSPITALS_DEVICES_REQUEST, HospitalActions.DELETE_HOSPITALS_DEVICES_SUCCESS, HospitalActions.DELETE_HOSPITALS_DEVICES_ERROR]
        }
    };
}
*/

export function saveHospital(HospitalID, values) {
    let type = "POST";
    let url = "hospitals";

    if(HospitalID && HospitalID > 0) {
        type = "PUT";
        url = "hospitals/"+HospitalID;
    }

    return {
        [CALL_API]: {
            reqType: type,
            endpoint: url,
            postData: values,
            types: [HospitalActions.SAVE_HOSPITAL_DETAIL_REQUEST,
                HospitalActions.SAVE_HOSPITAL_DETAIL_SUCCESS,
                HospitalActions.SAVE_HOSPITAL_DETAIL_ERROR]
        }
    };
}

/*
export function createHospital(values) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'hospitals',
            postData: values,
            types: [HospitalActions.CREATE_HOSPITAL_REQUEST,
                HospitalActions.CREATE_HOSPITAL_SUCCESS,
                HospitalActions.CREATE_HOSPITAL_ERROR]
        }
    };
}

export function editHospital(hospitalID, values) {
    return{
        [CALL_API]: {
            reqType: 'PUT',
            endpoint: 'hospitals/' + hospitalID,
            postData: values,
            types: [HospitalActions.EDIT_HOSPITAL_DETAIL_REQUEST,
                HospitalActions.EDIT_HOSPITAL_DETAIL_SUCCESS,
                HospitalActions.EDIT_HOSPITAL_DETAIL_ERROR]
        }
    };
}
*/

export function showPreviewFileModal(show, file = {}) {
    return {
        type: HospitalActions.SHOW_PREVIEW_FILE_MODAL,
        show,
        file
    };
};


export function clearHospitalDetail() {
    return {
        type: HospitalActions.CLEAR_HOSPITAL_DETAIL
    }
}

export function setHospitalsTablePage(pager) {
    return {
        type: HospitalActions.SET_HOSPITAL_TABLE_PAGE,
        pager
    };
}

export function setHospitalsTableInfo(infos) {
    return {
        type: HospitalActions.SET_HOSPITAL_TABLE_INFO,
        infos
    };
}

export function setHospitalsSearchText(search) {
    return {
        type: HospitalActions.SET_HOSPITAL_SEARCH_TEXT,
        search
    };
}
/*
export function setActiveStatus(activeStatus) {
    return {
        type: HospitalActions.SET_ACTIVE_STATUS,
        activeStatus
    };
}*/

export function setHospitalsImageLoading(loading) {
    return {
        type: HospitalActions.SET_HOSPITAL_IMAGE_LOADING,
        loading
    };
}
export function setHospitalsImage(image) {
    return {
        type: HospitalActions.SET_HOSPITAL_IMAGE,
        image
    };
}

export function showStaffModal(show) {
    return {
        type: HospitalActions.SHOW_STAFF_MODAL,
        show
    };
}

export function showPhotoTypeModal(show) {
    return {
        type: HospitalActions.SHOW_PHOTOTYPE_MODAL,
        show
    };
}
