import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Form, Icon, Input, Button, Select, Row, Col, Spin, Modal, Table} from 'antd';
import history from '../../history';
import * as EmployeeActions from "../../redux/Employees/actions";
import ActionFooter from '../../components/actionFooter';
import ContentContainer from "../../components/ContentContainer";

const Option = Select.Option;
const FormItem = Form.Item;
const confirm = Modal.confirm;




class EmployeesDetail extends Component {


    EmployeeID = null;

    componentWillMount() {
        this.refresh();
    }

    refresh() {
        this.EmployeeID = this.props.match.params.ID || null;

        this.props.actions.clearEmployeeDetail();
        this.props.form.resetFields();


        if(this.EmployeeID > 0) {
            this.props.actions.LoadEmployeeDetail(this.EmployeeID);
        }
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.savingEmployeeDetail && !nextProps.savingEmployeeDetail && nextProps.savingEmployeeDetailStatus >= 0) {

            if(nextProps.savingEmployeeDetailStatus > 0) {
                //history.replace("/app/employees/"+nextProps.savingEmployeeDetailStatus);
                window.location.href="/app/employees/"+nextProps.savingEmployeeDetailStatus;
                //this.refresh();
            } else {
                this.props.actions.LoadEmployeeDetail(this.EmployeeID);
            }
        }
        if(this.props.deletingEmployee && !nextProps.deletingEmployee) {
            history.replace("/app/employees");
        }
    }

    

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }
    


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.actions.saveEmployee( this.EmployeeID, values);
            }
        });
    };


    confirmDelete = () => {
        confirm({
            title: "Delete staff",
            content: "Do you really want to delete this staff?",
            okText: "Delete",
            okType: 'danger',
            cancelText: "cancel",
            onOk: () => this.props.actions.deleteEmployee(this.EmployeeID),
            onCancel() {
            },
        });
    };

    render() {


        const { getFieldDecorator, getFieldsError } = this.props.form;


        return (
                <React.Fragment>

                <div style={{display: 'flex'}}>

                    <ContentContainer head={<div className={"HeaderText"}>
                            <Icon type={"employee"} />
                            <span>{this.EmployeeID > 0 ? this.props.Employee.FirstName+" "+this.props.Employee.LastName : "Create Staff"}</span>
                    </div>}>


                    <Spin spinning={this.props.loadingEmployeeDetail}>

                            <Form id={"form"} layout="vertical" onSubmit={this.handleSubmit}>

                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"E-Mail"}
                                            >
                                                {getFieldDecorator('EMail', {
                                                    rules: [{ type: 'email', message: 'please enter a valid e-mail address'},
                                                        {required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.Employee.EMail
                                                })(
                                                    <Input type={"email"}/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Title"}
                                            >
                                                {getFieldDecorator('Title', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.Employee.Title
                                                })(
                                                    <Input />
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Last Name"}
                                            >
                                                {getFieldDecorator('LastName', {
                                                    rules: [{required: true, message: 'please fill this information'}],
                                                    initialValue: this.props.Employee.LastName
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"First Name"}
                                            >
                                                {getFieldDecorator('FirstName', {
                                                    rules: [{required: true, message: 'please fill this information'}],
                                                    initialValue: this.props.Employee.FirstName
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Phone"}
                                            >
                                                {getFieldDecorator('Phone', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.Employee.Phone
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Mobile"}
                                            >
                                                {getFieldDecorator('Mobile', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.Employee.Mobile
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Certification"}
                                            >
                                                {getFieldDecorator('Certification', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.Employee.Certification
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                    </Spin>

                </ContentContainer>

                </div>

                    <ActionFooter>
                    <div className="isoLeft">
                        <Button
                            type="default"
                            htmlType="button"
                            onClick={() => history.goBack()}
                        >
                            <Icon type="left"/>Back
                        </Button>
                    </div>
                    <div className="isoRight">
                        <div className={"ButtonGroup"}>
                            {this.EmployeeID && this.EmployeeID > 0 ? <Button
                                type={"danger"}
                                icon={"delete"}
                                onClick={this.confirmDelete}
                                disabled={this.props.deletingEmployee}
                                loading={this.props.deletingEmployee}
                            >
                                Delete employee
                            </Button> : ''}
                            <Button
                                type="primary"
                                htmlType="submit"
                                form={"form"}
                                icon={"check"}
                                loading={this.props.savingEmployeeDetail}
                                disabled={(this.hasErrors(getFieldsError()) || this.props.savingEmployeeDetail || !this.props.form.isFieldsTouched())}
                            >Save</Button>
                        </div>

                    </div>
                </ActionFooter>
                </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { EmployeeReducer } = state;
    const { Employee, Devices, editAllowed, EmployeeEdit, savingEmployeeDetail, savingEmployeeDetailStatus, loadingEmployeeDetail, deletingEmployee } = EmployeeReducer;
    return {
        Employee,
        Devices,
        editAllowed,

        EmployeeEdit,
        savingEmployeeDetail,
        savingEmployeeDetailStatus,
        loadingEmployeeDetail,

        deletingEmployee
    };
}

function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(EmployeeActions, dispatch)
    };
}

const EmployeeEditForm = Form.create()(EmployeesDetail);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEditForm);
