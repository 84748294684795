import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {Button, Table, Row, Col, Input, Icon} from "antd";
import connect from "react-redux/es/connect/connect";
import * as HospitalActions from "../../redux/Hospitals/actions";
import history from "../../history/index";
import ActionFooter from '../../components/actionFooter';
import ContentContainer from "../../components/ContentContainer";


const Search = Input.Search;


class Hospitals extends Component{

    showHospital(id) {
        history.push("/app/hospitals/" + id);
    }

    componentWillMount() {
        //this.props.actions.setActiveStatus("");
        this.props.actions.LoadHospitalTable({...this.props.infosTableHospitals, search: this.props.HospitalSearch, activeStatus: this.props.HospitalActiveStatus});
    }


    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.paginationHospitals };
        pager.current = pagination.current;

        this.props.actions.setHospitalsTablePage(pager);

        this.props.actions.setHospitalsTableInfo({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });

        this.props.actions.LoadHospitalTable({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
            search: this.props.HospitalSearch,
            activeStatus: this.props.HospitalActiveStatus
        });
    };

    onChangeSearch = (e) => {
        this.props.actions.setHospitalsSearchText(e.target.value);
    };



    render(){


        /*
        {
                title: '',
                dataIndex: 'id',
                key: 'icon',
                render: text => <Icon type={"hospital"}/>,
                width: 50
            }

            {
                title: '',
                dataIndex: 'FirstName',
                key: 'icon',
                render: text =>  <Avatar style={{ backgroundColor: '#b8e7cf', verticalAlign: 'middle', color: '#309969'}} size="large">{text.charAt(0).toUpperCase()}H</Avatar>,
                width: 50
            }
         */

        const columns = [
            {
                title: '',
                dataIndex: 'id',
                key: 'icon',
                render: text => <Icon type={"medicine-box"}/>,
                width: 50
            }, {
                title: 'HospitalID',
                dataIndex: 'HospitalID',
                key: 'id',
                sorter: true,
                sortOrder: this.props.infosTableHospitals.sortField === 'HospitalID' && this.props.infosTableHospitals.sortOrder,
            }, {
                title: 'Name',
                dataIndex: 'Name',
                key: 'Name',
                sorter: true,
                sortOrder: this.props.infosTableHospitals.sortField === 'Name' && this.props.infosTableHospitals.sortOrder,
            },
            {
                title: 'Street',
                dataIndex: 'Street',
                key: 'Street',
                sorter: true,
                sortOrder: this.props.infosTableHospitals.sortField === 'Street' && this.props.infosTableHospitals.sortOrder,
            },
            {
                title: 'Zip',
                dataIndex: 'ZIP',
                key: 'ZIP',
                sorter: true,
                sortOrder: this.props.infosTableHospitals.sortField === 'ZIP' && this.props.infosTableHospitals.sortOrder,
            },
            {
                title: 'City',
                dataIndex: 'City',
                key: 'City',
                sorter: true,
                sortOrder: this.props.infosTableHospitals.sortField === 'City' && this.props.infosTableHospitals.sortOrder,
            }
        ];



        return (
            <ContentContainer head={<Row style={{width: '100%'}}>
                <Col span={12} className={"HeaderText"}>
                    <Icon type={"medicine-box"} />
                    <span>Hospitals</span>
                </Col>
                <Col span={12}>
                    <Search onChange={this.onChangeSearch} value={this.props.HospitalSearch} suffix={<Icon type="close-circle" onClick={() => {this.props.actions.setHospitalsSearchText(''); this.props.actions.LoadHospitalTable( {...this.props.infosTableHospitals, search: ''});}} />} placeholder={"Search..."} onSearch={() => this.props.actions.LoadHospitalTable( {...this.props.infosTableHospitals, search: this.props.HospitalSearch, activeStatus: this.props.HospitalActiveStatus})}  enterButton style={{width: '400px', minHeight: '0px !important', float: 'right'}} />
                </Col>
            </Row>}>
                

                    <Table
                        columns={columns}
                        loading={this.props.loadingHospitalsList}
                        dataSource={this.props.Hospitals}
                        onRow={(record) => ({
                            onClick: () => {
                                this.showHospital(record.HospitalID);
                            }
                        })}
                        rowClassName={(record, index) => 'table-clickable'}
                        pagination={this.props.paginationHospitals}
                        onChange={this.handleTableChange}
                    />


                <ActionFooter>
                    <div className="isoLeft">
                        <Button
                            type="default"
                            htmlType="button"
                            onClick={() => history.goBack()}
                        >
                            <Icon type="left"/>Back
                        </Button>
                    </div>
                    <div className="isoRight">
                        <Button onClick={() => this.showHospital(0)} type="primary">
                            <Icon type="plus"/>Add
                        </Button>
                    </div>
                </ActionFooter>

            </ContentContainer>
        );
    }
}

function mapStateToProps(state) {
    const {HospitalReducer} = state;
    const {Hospitals, loadingHospitalsList, paginationHospitals, infosTableHospitals, HospitalSearch, HospitalActiveStatus} = HospitalReducer;
    return {
        Hospitals,
        loadingHospitalsList,
        paginationHospitals,
        infosTableHospitals,
        HospitalSearch,
        HospitalActiveStatus
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(HospitalActions, dispatch)
    };
}




export default connect(mapStateToProps, mapDispatchToProps)(Hospitals);