import { CALL_API } from '../../middleware/api';


const UserActions = {

    LOAD_TABLE_REQUEST: 'LOAD_TABLE_REQUEST',
    LOAD_TABLE_SUCCESS: 'LOAD_TABLE_SUCCESS',
    LOAD_TABLE_ERROR: 'LOAD_TABLE_ERROR',
    LOAD_USER_DETAIL_REQUEST: 'LOAD_USER_DETAIL_REQUEST',
    LOAD_USER_DETAIL_SUCCESS: 'LOAD_USER_DETAIL_SUCCESS',
    LOAD_USER_DETAIL_ERROR: 'LOAD_USER_DETAIL_ERROR',

    SAVE_USER_DETAIL_REQUEST: 'SAVE_USER_DETAIL_REQUEST',
    SAVE_USER_DETAIL_SUCCESS: 'SAVE_USER_DETAIL_SUCCESS',
    SAVE_USER_DETAIL_ERROR: 'SAVE_USER_DETAIL_ERROR',

    CLEAR_USER_DETAIL: 'CLEAR_USER_DETAIL',

    SET_USER_TABLE_PAGE: 'SET_USER_TABLE_PAGE',
    SET_USER_TABLE_INFO: 'SET_USER_TABLE_INFO',
    SET_USER_SEARCH_TEXT: 'SET_USER_SEARCH_TEXT',

    CHECK_USER_EMAIL_REQUEST: 'CHECK_USER_EMAIL_REQUEST',
    CHECK_USER_EMAIL_SUCCESS: 'CHECK_USER_EMAIL_SUCCESS',
    CHECK_USER_EMAIL_ERROR: 'CHECK_USER_EMAIL_ERROR',


    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: 'DELETE_USER_ERROR',

    SET_ACTIVE_STATUS: 'SET_ACTIVE_STATUS'
};

export default UserActions;


export function LoadUserDetail(userID) {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'users/' + userID,
            types: [UserActions.LOAD_USER_DETAIL_REQUEST, UserActions.LOAD_USER_DETAIL_SUCCESS, UserActions.LOAD_USER_DETAIL_ERROR]
        }
    };
}


export function deleteUser(userID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'users/' + userID,
            types: [UserActions.DELETE_USER_REQUEST, UserActions.DELETE_USER_SUCCESS, UserActions.DELETE_USER_ERROR]
        }
    };
}
/*export function LoadTable() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'users',
            types: [UserActions.LOAD_TABLE_REQUEST, UserActions.LOAD_TABLE_SUCCESS, UserActions.LOAD_TABLE_ERROR]
        }
    };
}*/

export function LoadUserTable(params= {}) {

    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'users/table',
            postData: {
                results: 10,
                ...params,
            },
            types: [UserActions.LOAD_TABLE_REQUEST, UserActions.LOAD_TABLE_SUCCESS, UserActions.LOAD_TABLE_ERROR]
        }
    };
}


/*
export function deleteDevices(userID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'users/'+userID+"/devices",
            types: [UserActions.DELETE_USERS_DEVICES_REQUEST, UserActions.DELETE_USERS_DEVICES_SUCCESS, UserActions.DELETE_USERS_DEVICES_ERROR]
        }
    };
}
*/

export function saveUser(UserID, values) {
    let type = "POST";
    let url = "users";

    if(UserID && UserID > 0) {
        type = "PUT";
        url = "users/"+UserID;
    }

    return {
        [CALL_API]: {
            reqType: type,
            endpoint: url,
            postData: values,
            types: [UserActions.SAVE_USER_DETAIL_REQUEST,
                UserActions.SAVE_USER_DETAIL_SUCCESS,
                UserActions.SAVE_USER_DETAIL_ERROR]
        }
    };
}

/*
export function createUser(values) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'users',
            postData: values,
            types: [UserActions.CREATE_USER_REQUEST,
                UserActions.CREATE_USER_SUCCESS,
                UserActions.CREATE_USER_ERROR]
        }
    };
}

export function editUser(userID, values) {
    return{
        [CALL_API]: {
            reqType: 'PUT',
            endpoint: 'users/' + userID,
            postData: values,
            types: [UserActions.EDIT_USER_DETAIL_REQUEST,
                UserActions.EDIT_USER_DETAIL_SUCCESS,
                UserActions.EDIT_USER_DETAIL_ERROR]
        }
    };
}
*/

export function checkUserEmail(UserID, value) {
    if(value && value.length > 0) {
        return {
            [CALL_API]: {
                reqType: 'POST',
                endpoint: 'users/check',
                postData: {UserID, email: value},
                types: [UserActions.CHECK_USER_EMAIL_REQUEST,
                    UserActions.CHECK_USER_EMAIL_SUCCESS,
                    UserActions.CHECK_USER_EMAIL_ERROR]
            }
        };
    } else {
        return {
            type: UserActions.CHECK_USER_EMAIL_SUCCESS
        }
    }
}
/*
export function checkUserActivation(UserID, value) {
    if(value && value.length > 0) {
        return {
            [CALL_API]: {
                reqType: 'POST',
                endpoint: 'users/check',
                postData: {UserID, activationcode: value},
                types: [UserActions.CHECK_USER_ACTIVATION_REQUEST,
                    UserActions.CHECK_USER_ACTIVATION_SUCCESS,
                    UserActions.CHECK_USER_ACTIVATION_ERROR]
            }
        };
    } else {
        return {
            type: UserActions.CHECK_USER_ACTIVATION_SUCCESS
        }
    }
}
*/
/*
export function setAllowEdit() {
    return {
        type: UserActions.SET_USER_ALLOWEDIT,
    };
}

export function setEditUser(edit) {
    return {
        type: UserActions.SET_USER_EDIT,
        edit
    };
}*/

export function clearUserDetail() {
    return {
        type: UserActions.CLEAR_USER_DETAIL
    }
}

export function setUsersTablePage(pager) {
    return {
        type: UserActions.SET_USER_TABLE_PAGE,
        pager
    };
}

export function setUsersTableInfo(infos) {
    return {
        type: UserActions.SET_USER_TABLE_INFO,
        infos
    };
}

export function setUsersSearchText(search) {
    return {
        type: UserActions.SET_USER_SEARCH_TEXT,
        search
    };
}

export function setActiveStatus(activeStatus) {
    return {
        type: UserActions.SET_ACTIVE_STATUS,
        activeStatus
    };
}