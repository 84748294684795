import React, {Component} from "react";
import {Button, Icon} from 'antd';

let BASE_URL = process.env.REACT_APP_API_URL;
let API_VERSION = process.env.REACT_APP_API_VERSION;


class FileDownload extends Component {

    token = localStorage.getItem('id_token') || null;

    downloadFile(type, FileID) {
        let url = BASE_URL+'api/' + API_VERSION + "/"+type+"/files/"+FileID;
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
        }).then(function(resp) {
            return resp.blob();
        }).then(function(blob) {
            //download(blob, "test");
            //var file = new Blob([blob], {type: 'images/png'});
                //--var file = blob;
                //--var fileURL = URL.createObjectURL(file);
                //--window.location.href= fileURL;
            //window.open(fileURL);

            let fileURL = URL.createObjectURL(blob);
            //window.open(fileURL);
            let fileLink = document.createElement('a');
            fileLink.href = fileURL;
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();

        });
    }

    componentWillMount() {

        this.downloadFile(this.props.match.params.type, this.props.match.params.FileID);

        // window.top.close();

    }



    render() {

        return (

                <div style={{textAlign: 'center'}}>

                    <Button onClick={() => this.downloadFile(this.props.match.params.type, this.props.match.params.FileID)} type={"primary"} htmlType={"button"} style={{display: 'block', margin: '0px auto', padding: '15px 25px', fontSize: '20px', height: 'auto'}}>
                        <Icon type={"download"} style={{fontSize: '65px'}}/>
                    </Button>
                </div>

        );
    }
}



export default FileDownload;
