import React, {Component} from "react";
import {Modal, Button} from 'antd';


class FileModal extends Component {

    componentDidUpdate() {
        //console.log(this.props.file);
    }

    render() {

        return (
            <Modal
                visible={this.props.visible}
                title="File preview"
                onOk={this.props.onCancel}
                onCancel={this.props.onCancel}
                cancelText={"Close"}
                width={"750px"}
                footer={[
                    <Button key="back" icon={"download"} type="default" onClick={() => window.open(this.props.file.url, "_blank")}>
                        Download
                    </Button>
                    ,
                    <Button key="submit" icon={"close"} type="primary" onClick={this.props.onCancel}>
                        Close
                    </Button>,
                ]}
            >

                {this.props.file.thumbUrl ? <img src={this.props.file.thumbUrl} style={{  width: "100%",  height: "auto" } } /> : <h2>no preview available</h2>}


            </Modal>
        );
    }
}



export default FileModal;
