import { CALL_API } from '../../middleware/api';

const actions = {
    LOAD_ALL_EMERGENCY_REQUEST: 'LOAD_ALL_EMERGENCY_REQUEST',
    LOAD_ALL_EMERGENCY_SUCCESS: 'LOAD_ALL_EMERGENCY_SUCCESS',
    LOAD_ALL_EMERGENCY_ERROR: 'LOAD_ALL_EMERGENCY_ERROR',

    LOAD_TABLE_REQUEST: 'LOAD_TABLE_REQUEST',
    LOAD_TABLE_SUCCESS: 'LOAD_TABLE_SUCCESS',
    LOAD_TABLE_ERROR: 'LOAD_TABLE_ERROR',

    SET_EMERGENCYCASES_TABLE_PAGE: 'SET_EMERGENCYCASES_TABLE_PAGE',
    SET_EMERGENCYCASES_TABLE_INFO: 'SET_EMERGENCYCASES_TABLE_INFO',
    SET_EMERGENCYCASES_SEARCH_TEXT: 'SET_EMERGENCYCASES_SEARCH_TEXT',

    LOAD_EMERGENCYS_REQUEST: 'LOAD_EMERGENCYS_REQUEST',
    LOAD_EMERGENCYS_SUCCESS: 'LOAD_EMERGENCYS_SUCCESS',
    LOAD_EMERGENCYS_ERROR: 'LOAD_EMERGENCYS_ERROR',

    SHOW_EMERGENCYCASE_MODAL: 'SHOW_EMERGENCYCASE_MODAL',

    SAVE_EMERGENCYCASE_REQUEST: 'SAVE_EMERGENCYCASE_REQUEST',
    SAVE_EMERGENCYCASE_SUCCESS: 'SAVE_EMERGENCYCASE_SUCCESS',
    SAVE_EMERGENCYCASE_ERROR: 'SAVE_EMERGENCYCASE_ERROR',

};

export default actions;




export function LoadEmergencyCaseTable(params= {}) {

    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'emergency/table',
            postData: {
                results: 10,
                ...params,
            },
            types: [actions.LOAD_TABLE_REQUEST, actions.LOAD_TABLE_SUCCESS, actions.LOAD_TABLE_ERROR]
        }
    };
}


export function loadAllEmergencys() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'emergency/all',
            types: [actions.LOAD_ALL_EMERGENCY_REQUEST, actions.LOAD_ALL_EMERGENCY_SUCCESS, actions.LOAD_ALL_EMERGENCY_ERROR]
        }
    };
}

export function loadEmergencys() {
    return {
        [CALL_API]: {
            reqType: 'GET',
            endpoint: 'emergency',
            types: [actions.LOAD_EMERGENCYS_REQUEST, actions.LOAD_EMERGENCYS_SUCCESS, actions.LOAD_EMERGENCYS_ERROR]
        }
    };
}



export function saveEmergencyCase(values, CaseID) {
    return {
        [CALL_API]: {
            reqType: 'PUT',
            endpoint: 'emergency/case/'+CaseID,
            postData: values,
            types: [actions.SAVE_EMERGENCYCASE_REQUEST, actions.SAVE_EMERGENCYCASE_SUCCESS, actions.SAVE_EMERGENCYCASE_ERROR]
        }
    };
}

export function createEmergencyCase(values) {
    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'emergency/case',
            postData: values,
            types: [actions.SAVE_EMERGENCYCASE_REQUEST, actions.SAVE_EMERGENCYCASE_SUCCESS, actions.SAVE_EMERGENCYCASE_ERROR]
        }
    };
}

export function deleteEmergencyCase(CaseID) {
    return {
        [CALL_API]: {
            reqType: 'DELETE',
            endpoint: 'emergency/case/'+CaseID,
            types: [actions.SAVE_EMERGENCYCASE_REQUEST, actions.SAVE_EMERGENCYCASE_SUCCESS, actions.SAVE_EMERGENCYCASE_ERROR]
        }
    };
}


export function showEmergencyCaseModal(show, emergency = {}) {
    return {
        type: actions.SHOW_EMERGENCYCASE_MODAL,
        show,
        emergency
    };
}

export function setEmergencyCasesTablePage(pager) {
    return {
        type: actions.SET_EMERGENCYCASES_TABLE_PAGE,
        pager
    };
}

export function setEmergencyCasesTableInfo(infos) {
    return {
        type: actions.SET_EMERGENCYCASES_TABLE_INFO,
        infos
    };
}

export function setEmergencyCasesSearchText(search) {
    return {
        type: actions.SET_EMERGENCYCASES_SEARCH_TEXT,
        search
    };
}