import EmployeeActions from "./actions";

const initState = {
    Employees: [],
    Employee: {},
    loadingEmployeesList: false,
    paginationEmployees: {},
    infosTableEmployees: {sortField: 'EmployeeID', sortOrder: 'descend'},
    EmployeeSearch: "",

    AllEmployees: [],

    deletingEmployee: false,

    savingEmployeeDetail: false,
    savingEmployeeDetailStatus: 0,
    loadingEmployeeDetail: false
};

export default function EmployeeReducer(state = initState, action) {
  switch (action.type) {
      case EmployeeActions.LOAD_ALL_EMPLOYEE_REQUEST:
          return Object.assign({}, state, {
              AllEmployees: []
          });
      case EmployeeActions.LOAD_ALL_EMPLOYEE_SUCCESS:
          return Object.assign({}, state, {
              AllEmployees: action.response.data.Employees
          });

    case EmployeeActions.LOAD_TABLE_REQUEST:
        return Object.assign({}, state, {
            loadingEmployeesList: true
        });
    case EmployeeActions.LOAD_TABLE_SUCCESS:
        const pagination = { ...state.paginationEmployees };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = action.response.data.Count;

        return Object.assign({}, state, {
            Employees: action.response.data.Employees,
            editAllowed: action.response.data.editAllowed,
            loadingEmployeesList: false,
            paginationEmployees: pagination
        });
      case EmployeeActions.LOAD_TABLE_ERROR:
          return Object.assign({}, state, {
              loadingEmployeesList: false
          });
      case EmployeeActions.SET_EMPLOYEE_TABLE_PAGE:
          return Object.assign({}, state, {
              paginationEmployees: action.pager
          });
      case EmployeeActions.SET_EMPLOYEE_TABLE_INFO:
          return Object.assign({}, state, {
              infosTableEmployees: action.infos
          });
      case EmployeeActions.SET_EMPLOYEE_SEARCH_TEXT:
          return Object.assign({}, state, {
              EmployeeSearch: action.search,
              paginationEmployees: {current: 1},
              infosTableEmployees: {}
          });
      case EmployeeActions.LOAD_EMPLOYEE_DETAIL_REQUEST:
          return Object.assign({}, state, {
              loadingEmployeeDetail: true
          });
      case EmployeeActions.LOAD_EMPLOYEE_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              Employee: action.response.data.Employee,
              Devices: action.response.data.Devices,
              editAllowed: action.response.data.editAllowed,
              loadingEmployeeDetail: false
          });
      case EmployeeActions.LOAD_EMPLOYEE_DETAIL_ERROR:
          return Object.assign({}, state, {
              loadingEmployeeDetail: false
          });

      case EmployeeActions.SAVE_EMPLOYEE_DETAIL_REQUEST:
          return Object.assign({}, state, {
              savingEmployeeDetail: true,
              savingEmployeeDetailStatus: -2
          });
      case EmployeeActions.SAVE_EMPLOYEE_DETAIL_SUCCESS:
          return Object.assign({}, state, {
              savingEmployeeDetail: false,
              savingEmployeeDetailStatus: action.response.data.EmployeeID  || 0
          });
      case EmployeeActions.SAVE_EMPLOYEE_DETAIL_ERROR:
          return Object.assign({}, state, {
              savingEmployeeDetail: false,
              savingEmployeeDetailStatus: -1
          });
      
      case EmployeeActions.CLEAR_EMPLOYEE_DETAIL:
          return Object.assign({}, state, {
              Employee: {},
              Devices: []
          });



      case EmployeeActions.DELETE_EMPLOYEE_REQUEST:
          return Object.assign({}, state, {
              deletingEmployee: true
          });
      case EmployeeActions.DELETE_EMPLOYEE_SUCCESS:
          return Object.assign({}, state, {
              deletingEmployee: false
          });
      case EmployeeActions.DELETE_EMPLOYEE_ERROR:
          return Object.assign({}, state, {
              deletingEmployee: false
          });
    default:
      return state;
  }
}

