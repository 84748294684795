import { CALL_API } from '../../middleware/api';
import actions from "../Emergency/actions";


const NotificationActions = {


    SEND_NOTIFICATION_REQUEST: 'SEND_NOTIFICATION_REQUEST',
    SEND_NOTIFICATION_SUCCESS: 'SEND_NOTIFICATION_SUCCESS',
    SEND_NOTIFICATION_ERROR: 'SEND_NOTIFICATION_ERROR',

    LOAD_TABLE_NOTIFICATION_REQUEST: 'LOAD_TABLE_NOTIFICATION_REQUEST',
    LOAD_TABLE_NOTIFICATION_SUCCESS: 'LOAD_TABLE_NOTIFICATION_SUCCESS',
    LOAD_TABLE_NOTIFICATION_ERROR: 'LOAD_TABLE_NOTIFICATION_ERROR',

    SET_NOTIFICATION_TABLE_PAGE: 'SET_NOTIFICATION_TABLE_PAGE',

    SET_NOTIFICATION_TABLE_INFO: 'SET_NOTIFICATION_TABLE_INFO',

    SET_NOTIFICATION_SEARCH_TEXT: 'SET_NOTIFICATION_SEARCH_TEXT',

    SHOW_NEW_NOTIFICATION_MODAL: 'SHOW_NEW_NOTIFICATION_MODAL'

};

export default NotificationActions;


export function SendNotification(values= {}) {

    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'notification/send',
            postData: values,
            types: [NotificationActions.SEND_NOTIFICATION_REQUEST, NotificationActions.SEND_NOTIFICATION_SUCCESS, NotificationActions.SEND_NOTIFICATION_ERROR]
        }
    };
}

export function LoadNotificationTable(params= {}) {

    return {
        [CALL_API]: {
            reqType: 'POST',
            endpoint: 'notification/table',
            postData: {
                results: 10,
                ...params,
            },
            types: [NotificationActions.LOAD_TABLE_NOTIFICATION_REQUEST, NotificationActions.LOAD_TABLE_NOTIFICATION_SUCCESS, NotificationActions.LOAD_TABLE_NOTIFICATION_ERROR]
        }
    };
}


export function setNotificationsTablePage(pager) {
    return {
        type: NotificationActions.SET_NOTIFICATION_TABLE_PAGE,
        pager
    };
}

export function setNotificationsTableInfo(infos) {
    return {
        type: NotificationActions.SET_NOTIFICATION_TABLE_INFO,
        infos
    };
}

export function setNotificationsSearchText(search) {
    return {
        type: NotificationActions.SET_NOTIFICATION_SEARCH_TEXT,
        search
    };
}


export function showNewNotificationModal(show) {
    return {
        type: NotificationActions.SHOW_NEW_NOTIFICATION_MODAL,
        show,
    };
}
