import React, {Component} from "react";
import { Form, Modal, Input, Button, Row, Col } from 'antd';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as NotificationActions from "../redux/Notification/actions";

const TextArea = Input.TextArea;
const FormItem = Form.Item;
const confirm = Modal.confirm;


const formItemLayout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 13 },
    },
};



class NewNotificationModal extends Component {



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                confirm({
                    title: "Send Notification",
                    content: "Do you really want to send this notification?",
                    okText: "Send",
                    okType: 'primary',
                    cancelText: "cancel",
                    onOk: () => {
                        this.props.actions.SendNotification(values);
                    },
                    onCancel() {
                    },
                });
            }
        });
    };


    componentWillReceiveProps(nextProps) {
        if(this.props.NewNotificationModalVisible && !nextProps.NewNotificationModalVisible) {
            this.props.form.resetFields();
        }
    }




    render() {
        const { getFieldDecorator } = this.props.form;


        return (
                <Modal
                    title={`Send notification`}
                    visible={this.props.NewNotificationModalVisible}
                    onOk={this.handleSubmit}
                    onCancel={() => this.props.actions.showNewNotificationModal(false)}
                    cancelText={"Cancel"}
                    okText={"Send"}
                    width={"750px"}
                    footer={[
                        <Button key="cancel" icon={"close"} onClick={() => this.props.actions.showNewNotificationModal(false)}>Cancel</Button>,
                        <Button key="submit" icon={"check"} type="primary" loading={this.props.savingNewNotification} onClick={this.handleSubmit}>
                            Send
                        </Button>
                    ]}
                >

                    <Form id={"form"} onSubmit={this.handleSubmit} style={{width: '100%'}}>

                        <Row>
                            <Col span={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label={"Title"}
                                    hasFeedback={true}
                                >
                                    {getFieldDecorator('Title', {
                                        rules: [{required: true, message: 'please fill this information'}],
                                    })(
                                        <Input type={"text"} disabled={this.props.savingNewNotification}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormItem
                                    {...formItemLayout}
                                    label={"Notification Text"}
                                >
                                    {getFieldDecorator('Text', {
                                        rules: [{required: true, message: 'please fill this information'}],
                                    })(
                                        <TextArea rows={5} disabled={this.props.savingNewNotification} />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                    </Form>


                </Modal>


        );
    }
}



function mapStateToProps(state) {
    const {NotificationReducer} = state;
    const {NewNotificationModalVisible, savingNewNotification} = NotificationReducer;
    return {
        NewNotificationModalVisible,
        savingNewNotification
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(NotificationActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewNotificationModal));
