import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Form, Icon, Input, Button, Select, Row, Col, Spin, Modal, Table} from 'antd';
import history from '../../history';
import * as UserActions from "../../redux/Users/actions";
import ActionFooter from '../../components/actionFooter';
import ContentContainer from "../../components/ContentContainer";

const Option = Select.Option;
const FormItem = Form.Item;
const confirm = Modal.confirm;



const columnsDeviceList = [
    {
        title: '',
        dataIndex: 'client',
        key: 'icon',
        render: text =>  text.toLowerCase() === "iphone" ? <Icon type={"apple"} /> : <Icon type={"android"}/> ,
        width: 50
    },
    {
        title: 'Device ID',
        dataIndex: 'device_id',
        key: 'DeviceID',
    }, {
        title: 'Client',
        dataIndex: 'client',
        key: 'Client',
    },{
        title: 'OS Version',
        dataIndex: 'os_version',
        key: 'OSVersion',
    },{
        title: 'APP Version',
        dataIndex: 'app_version',
        key: 'APPVersion',
    }, {
        title: 'Build',
        dataIndex: 'build',
        key: 'Build',
    },
    {
        title: 'Push Enabled',
        dataIndex: 'pushtoken_available',
        key: 'pushtoken_available',
        render: text =>  text ? <Icon type={"check"} /> : <Icon type={"close"}/> ,
    }];



class UsersDetail extends Component {


    UserID = null;

    componentWillMount() {
        this.UserID = this.props.match.params.ID || null;

        this.props.actions.clearUserDetail();
        this.props.form.resetFields();


        if(this.UserID > 0) {
            this.props.actions.LoadUserDetail(this.UserID);
        }

    }


    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.savingUserDetail && !nextProps.savingUserDetail && nextProps.savingUserDetailStatus >= 0) {

            if(nextProps.savingUserDetailStatus > 0) {
                //history.replace("/app/users/"+nextProps.savingUserDetailStatus);
                window.location.href="/app/employees/"+nextProps.savingEmployeeDetailStatus;
            } else {
                this.props.actions.LoadUserDetail(this.UserID);
            }
        }
        if(this.props.deletingUser && !nextProps.deletingUser) {
            history.replace("/app/users");
        }
    }



    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.actions.saveUser( this.UserID, values);
            }
        });
    };

    /*removeContainer = (k) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    addContainer = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(uuid);
        uuid++;

        form.setFieldsValue({
            keys: nextKeys,
        });
    };*/

    checkExistingEmail = (e) => {
        this.props.actions.checkUserEmail( this.UserID,e.target.value );
    };


    confirmDelete = () => {
        confirm({
            title: "Delete user",
            content: "Do you really want to delete this user?",
            okText: "Delete",
            okType: 'danger',
            cancelText: "cancel",
            onOk: () => this.props.actions.deleteUser(this.UserID),
            onCancel() {
            },
        });
    };

    render() {


        const { getFieldDecorator, getFieldsError } = this.props.form;
        //getFieldDecorator('keys', { initialValue: this.props.User.Containers ? this.props.User.Containers.map((c, i) => {return i;} ) : []} );
        //const keys = this.props.form.getFieldValue('keys');
        //if(this.props.User.Containers && uuid < this.props.User.Containers.length) { uuid = this.props.User.Containers.length; }


        /*const formItems = keys.map((k, index) => {
            return (


                <FormItem
                    label={''}
                    required={false}
                    key={k}
                >
                    {getFieldDecorator(`containers[${k}]`, {
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            required: true,
                            message: this.props.strings.RequiredOrDeleteMessage,
                        }],
                        initialValue: this.props.User.Containers ? this.props.User.Containers[k] ? this.props.User.Containers[k] : "" : "",
                    })(

                        <Select getPopupContainer={trigger => trigger.parentNode} style={{ width: '92%', marginRight: 8 }}>
                            {this.props.AllContainers.map((container) => <Option key={container.ContainerID}
                                                                              value={container.ContainerID} >{container.Name}</Option>)}
                        </Select>
                    )}
                    {
                        <Icon
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            onClick={() => this.removeContainer(k)}
                        />
                    }
                </FormItem>

            );
        });*/

        return (
                <React.Fragment>

                <div style={{display: 'flex'}}>

                    <ContentContainer head={<div className={"HeaderText"}>
                            <Icon type={"user"} />
                            <span>{this.UserID > 0 ? this.props.User.FirstName+" "+this.props.User.LastName : "Create User"}</span>
                    </div>} borderRight>


                    <Spin spinning={this.props.loadingUserDetail}>

                            <Form id={"form"} layout="vertical" onSubmit={this.handleSubmit}>

                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"E-Mail"}
                                                hasFeedback={true}
                                                validateStatus={this.props.UserEmailValidateStatus}
                                                help={this.props.UserEmailValidateStatus === "error" ? 'this email address already exists' : this.props.UserEmailValidateStatus === 'validating' ? 'checking...' : undefined}
                                            >
                                                {getFieldDecorator('EMail', {
                                                    rules: [{ type: 'email', message: 'please enter a valid e-mail address'},
                                                        {required: true, message: 'please fill this information'}],
                                                    initialValue: this.props.User.EMail
                                                })(
                                                    <Input type={"email"} onBlur={this.checkExistingEmail}/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Password"}
                                            >
                                                {getFieldDecorator('Password', {
                                                    rules: [{required: (!this.UserID || this.UserID === "0"), message: 'please fill this information'}],
                                                })(
                                                    <Input type={"password"} placeholder={"unchanged"} />
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Last Name"}
                                            >
                                                {getFieldDecorator('LastName', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.User.LastName
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"First Name"}
                                            >
                                                {getFieldDecorator('FirstName', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.User.FirstName
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Phone"}
                                            >
                                                {getFieldDecorator('Phone', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.User.Phone
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Organisation"}
                                            >
                                                {getFieldDecorator('Organisation', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.User.Organisation
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Certification"}
                                            >
                                                {getFieldDecorator('Certification', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.User.Certification
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"State"}
                                            >
                                                {getFieldDecorator('State', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.User.State
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"County"}
                                            >
                                                {getFieldDecorator('County', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.User.County
                                                })(
                                                    <Input/>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={20}><FormItem
                                                label={"Active"}
                                            >
                                                {getFieldDecorator('Active', {
                                                    rules: [{required: false, message: 'please fill this information'}],
                                                    initialValue: this.props.User.Active
                                                })(
                                                    <Select getPopupContainer={trigger => trigger.parentNode}>
                                                        <Option value={0} key={"0"}>not confirmed</Option>
                                                        <Option value={1} key={"1"}>active</Option>
                                                        <Option value={-1} key={"-1"}>blocked</Option>
                                                    </Select>
                                                )}
                                            </FormItem></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                    </Spin>

                    </ContentContainer>

                        <ContentContainer head={<div className={"HeaderText"}>
                            <Icon type={"mobile"} />
                            <span>Devices</span>
                        </div>}>
                            <Table
                                columns={columnsDeviceList}
                                dataSource={this.props.Devices} />
                        </ContentContainer>
                </div>

                    <ActionFooter>
                    <div className="isoLeft">
                        <Button
                            type="default"
                            htmlType="button"
                            onClick={() => history.goBack()}
                        >
                            <Icon type="left"/>Back
                        </Button>
                    </div>
                    <div className="isoRight">
                        <div className={"ButtonGroup"}>
                            {this.UserID && this.UserID > 0 ? <Button
                                type={"danger"}
                                icon={"delete"}
                                onClick={this.confirmDelete}
                                disabled={this.props.deletingUser}
                                loading={this.props.deletingUser}
                            >
                                Delete user
                            </Button> : ''}
                            <Button
                                type="primary"
                                htmlType="submit"
                                form={"form"}
                                icon={"check"}
                                loading={this.props.savingUserDetail}
                                disabled={(this.hasErrors(getFieldsError()) || this.props.savingUserDetail || !this.props.form.isFieldsTouched())}
                            >Save</Button>
                        </div>

                    </div>
                </ActionFooter>
                </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { UserReducer } = state;
    const { User, Devices, editAllowed, UserEdit, savingUserDetail, savingUserDetailStatus, loadingUserDetail, UserEmailValidateStatus, deletingUser } = UserReducer;
    return {
        User,
        Devices,
        editAllowed,

        UserEdit,
        savingUserDetail,
        savingUserDetailStatus,
        loadingUserDetail,

        UserEmailValidateStatus,
        deletingUser
    };
}

function mapDispatchToProps(dispatch) {
    return{
        actions: bindActionCreators(UserActions, dispatch)
    };
}

const UserEditForm = Form.create()(UsersDetail);

export default connect(mapStateToProps, mapDispatchToProps)(UserEditForm);
